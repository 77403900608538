import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import Logo from '../../assets/images/of_logo.svg';
import { Paths } from '../../routes/routePaths';
import { useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ContactDetailsForm from './contactDetailsForm';
import ShippingDetails from './shippingDetails';
import AccountLogin from './accountLogin';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDataById } from '../../operations/onboarding';
import CartActions from '../../redux/reducer/cartReducer';
import GlobalActions from '../../redux/reducer/globalReducer';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    height: 56,
    width: 'auto',
    cursor: 'pointer',
  },
  leftSection: {
    height: "50%",
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    color: colors.primary.main,
    backgroundColor: '#fff!important',
    border: '1px solid' + colors.info.lighter,
  },
}));

function getStepContent(step, handleBack, handleNext, user, userData, promocode,discount) {
  switch (step) {
    case 0:
      return <AccountLogin handleNext={handleNext} />;
    case 1:
      return <ContactDetailsForm user={user} userData={userData} handleNext={handleNext} />;
    case 2:
      return <ShippingDetails user={user} handleBack={handleBack} promocode={promocode} discount={discount}/>;
    default:
      return null;
  }
}

const AccountInformation = ({ user ,changestate ,promocode,discount,}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isUserFetch, setIsUserFetch] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  console.log('activeStep::AccountInformation', activeStep);
  const steps = ['Account', 'Information', 'Shipping Details'];

  let { loggedInStatus } = useSelector((state) => state.global);

  const [userData, setUserData] = useState({});

  const dispatch = useDispatch();



  const getUserData = async (user) => {
    const userData = await fetchUserDataById(user.uid);
    setUserData(userData);
    console.log("userdata---",userData);
    if (userData.cart) {
      dispatch(CartActions.setCart({ cart: userData.cart }));
    }
  };

  useEffect(() => {
    async function fetchData(user) {
      await getUserData(user);
    }


    if (user) {
      fetchData(user);
    }

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loggedInStatus]);

  useEffect(() => {

    if (user && loggedInStatus) {    
     console.log(" after loggeedd inn");
     setIsUserFetch(true);
      async function fetchData(user) {
      const userdata =  await getUserData(user);
        setActiveStep(1);
        setIsUserFetch(false);
      }
  
  
      if (user) {
        fetchData(user);
      }
    }
   
  }, [user, loggedInStatus]);

  useEffect(() => {
    changestate(activeStep)
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grid container direction="row" item xs={12} sm={5} className={classes.leftSection}>
       
      <Grid container item xs={12} sm={12}>

      <Grid item xs={12} sm={12} style={{ padding: '82px 0' }}>
        <img
          src={Logo}
          alt="Orange Figs"
          onClick={() => {
            history.push(Paths.SummerCamp);
            dispatch({
              type: GlobalActions.setCartStatus.SUCCESS,
              payload: false,
            });
          }}
          className={classes.logo}
        />
      </Grid>
     

        <Grid item xs={12} sm={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={index + 1} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {isUserFetch ? 
              <CircularProgress style={{marginLeft: "45%", marginTop: "30%"}}/>
            : <div>
              {
              getStepContent(
                activeStep,
                handleBack,
                handleNext,
                user,
                userData,
                promocode,
                discount
              )}
            </div>
          }
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountInformation;
