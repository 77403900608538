import { createAction } from '../../redux/actionSaga';
import {Paths} from './../../routes/routePaths'
export const CartActionTypes = {
  ADD_TO_CART: 'CART/ADD',
  REMOVE_FROM_CART: 'CART/REMOVE',
  UPDATE_CART: 'CART/UPDATE',
  RESET_CART: 'CART/RESET',
  SET_CART: 'CART/SET',
  ADD_PROMOCODE: 'CART/ADD_PROMOCODE',
  SET_GIFTS:'CART/SET_GIFTS',
  SET_COUNTRY:'CART/SET_COUNTRY'
};

const CartActions = {
  updateCart: createAction(CartActionTypes.UPDATE_CART),
  addToCart: createAction(CartActionTypes.ADD_TO_CART),
  removeFromCart: createAction(CartActionTypes.REMOVE_FROM_CART),
  resetCart: createAction(CartActionTypes.RESET_CART),
  setCart: createAction(CartActionTypes.SET_CART),
  addPromoCode: createAction(CartActionTypes.ADD_PROMOCODE),
  setGifting:createAction(CartActionTypes.SET_GIFTS),
  setCounrty:createAction(CartActionTypes.SET_COUNTRY),
};

const initialState = {
  loading: false,
  products: [],
  subscriptions: [],
  snacks: [],
  grandTotal: 0,
  shippingTotal: 0,
  tax: 0,
  subTotal: 0,
  promoCodeAmount: 0,
  giftdetails:false,
  country:'India',
  currencySymbol:'₹'
};

export const CartReducer = (state = initialState, action) => {
  switch (action.type) {

    case CartActions.setGifting.REQUEST:
      return {
        ...state,
        giftdetails: true,
        loading: true,
      };

    case CartActions.setGifting.SUCCESS: {
      // const { Giftdetails } = action.payload;
      console.log(action.payload);
      return {
        ...state,
        giftdetails: true,
        loading: false,
      };
    }
    case CartActions.setGifting.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CartActions.addPromoCode.REQUEST:
    case CartActions.addToCart.REQUEST:
    case CartActions.removeFromCart.REQUEST:
    case CartActions.updateCart.REQUEST:
    case CartActions.setCart.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CartActions.addToCart.SUCCESS:
    case CartActions.removeFromCart.SUCCESS:
    case CartActions.setCart.SUCCESS:
    case CartActions.updateCart.SUCCESS: {
      const { subscriptions = [], products = [], snacks = [] } = action.payload;

      const subscriptionTotal = subscriptions.length
        ? subscriptions.reduce((a, b) => {
            return a + (b.price - (b.discount_price ? b.discount_price : 0)) * b.qty;
          }, 0)
        : 0;
      const productsTotal = products.length
        ? products.reduce((a, b) => {
            return a + (b.price - (b.discount_price ? b.discount_price : 0)) * b.qty;
          }, 0)
        : 0;
      const snacksTotal = snacks.length
        ? snacks.reduce((a, b) => {
            return a + (b.price - (b.discount_price ? b.discount_price : 0)) * b.qty;
          }, 0)
        : 0;

      // Shipping
      const kitsShippingTotal = products.length
        ? products.reduce((a, b) => {
            // return a * b.qty;
            // return 200;
            return 0;
          }, 38)
        : 0;
      const snacksShippingTotal = snacks.length
        ? snacks.reduce((a, b) => {
            // return a * b.qty;
            // return 200;
            return 0;
          }, 38)
        : 0;
      const subscriptionShippingTotal = subscriptions.length
        ? subscriptions.reduce((a, b) => {
            // return a * b.qty * b.period;
            // return 200;
            return 0;
          }, 62)
        : 0;

      const tax = (subscriptionTotal +
        productsTotal) * 0;

      return {
        ...state,
        subscriptions,
        products,
        snacks,
        shippingTotal: kitsShippingTotal+snacksShippingTotal+subscriptionShippingTotal,
        subTotal: 
        subscriptionTotal +
          productsTotal +
          snacksTotal,
        grandTotal: 
          subscriptionTotal +
          productsTotal +
          snacksTotal +
          kitsShippingTotal +
          snacksShippingTotal +
          subscriptionShippingTotal + tax,
        tax,
        loading: false,

      };
    }
    case CartActions.addPromoCode.SUCCESS:{
      let {payload}= action.payload;
      console.log("inside reducer success",payload);
      const { grandTotal,discountamount , } = payload;
      // const { subscriptions = [], products = [], snacks = [] } = payload;

      return {
        ...state,
        grandTotal: grandTotal-discountamount,
        loading: false,
      };
      
    };
    case CartActions.addPromoCode.FAILURE:
    case CartActions.addToCart.FAILURE:
    case CartActions.removeFromCart.FAILURE:
    case CartActions.updateCart.FAILURE:
    case CartActions.setCart.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CartActions.resetCart.REQUEST:
      return {
        ...initialState,
      };
    case CartActions.setCounrty.SUCCESS:
      const { country = 'India', currencySymbol = '₹',homePath='/' } = action.payload;
      console.log("payload-",action.payload,"act vals--",country,currencySymbol)
      Paths.SummerCamp = homePath;
      return {
        ...state,
        country,
        currencySymbol
      };
    default: {
      return state;
    }
  }
};

export default CartActions;
