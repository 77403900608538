import firebase from 'firebase/app';
import { firestore, auth, firebaseFunctions } from '../../firebase';
import Message from '../../components/Message';
import {
  currentServerTime,
  UnableToUpdate,
  LeadCreatedSuccessFully
} from '../utils';
import { sendEmail } from '../common';

/**
 * Sign in using google
 * @returns {Promise<boolean>}
 */
const signInWithGoogle = async (cart) => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const signInRes = await auth.signInWithPopup(provider);
    if (signInRes.additionalUserInfo.isNewUser) {
      await registerUser(signInRes);

      // to customer
      sendEmail(
        `Welcome to orange figs.`,
        `Welcome text. Links. Etc.`,
        signInRes.user.email
      );
    }
    await updateUserData(signInRes.user.uid, {
      cart: {
        products: cart.products,
        subscriptions: cart.subscriptions,
        snacks:cart.snacks,
        grandTotal: cart.grandTotal,
      },
    });
    return true;
  } catch (e) {
    Message.error('Unable to connect to your google account.');
    return false;
  }
};

/**
 * Register user
 * @param createUser
 * @returns {Promise<*>}
 */

const registerUser = async (createUser) => {
  try {
    await firestore.collection('users').doc(createUser.user.uid).set({
      email: createUser.user?.email || '',
      name: createUser.user?.displayName || '',
      phone: createUser.user.phoneNumber ? createUser.user.phoneNumber : '',
      createdTime: currentServerTime,
      updatedTime: currentServerTime,
    });
    return createUser;
  } catch (e) {
    console.log('reg user-',e);
    return e;
  }
};

const requestOTP = async (phoneNumber, captcha) => {
  try {
    const res = await auth.signInWithPhoneNumber(phoneNumber, captcha);
    window.otpResponse = res;
    return res;
  } catch (e) {
    console.log("login err-",e)
    Message.error('Unable to sign in at the moment.');
    return false;
  }
};

/**
 * Confirm code
 * @param otpResponse: Response of above request
 * @param otp: string
 * @param cart
 * @returns {Promise<FirebaseAuthTypes.User|*>}
 */
const confirmCode = async (otp, cart) => {
  try {
    const res = await window.otpResponse.confirm(otp);
    if(res.additionalUserInfo.isNewUser){
      await registerUser(res)
      .then(async(resp)=>{
        await updateUserData(resp.user.uid, {
          cart: {
            products: cart.products,
            subscriptions: cart.subscriptions,
            snacks:cart.snacks,
            grandTotal: cart.grandTotal,
          }
        });
      })
      .catch((err)=>{
        console.log("err-",err);
        UnableToUpdate();
      });
    }
    else {
      await updateUserData(res.user.uid, {
        cart: {
          products: cart.products,
          subscriptions: cart.subscriptions,
          snacks:cart.snacks,
          grandTotal: cart.grandTotal,
        }
      });
    }
    return true;
  } catch (e) {
    Message.error('Please check your OTP.');
    return false;
  }
};

/**
 * Fetch user by id
 * @param uid
 * @returns {Promise<(T&{id: *})|(undefined&{id: *})>}
 */

const fetchUserDataById = async (uid) => {
  try {
    const userData = await firestore.collection('users').doc(uid).get();
    if (!userData.empty) {
      return { ...userData.data(), id: userData.id };
    }
    throw Error('User not found.');
  } catch (e) {
    Message.error(e.message);
  }
};

/**
 * Update Gift details
 */
const updateGiftData = async ( details) => {
  try {
await firestore.collection('GiftingDetails').add({
  GiftTo: details.GiftTo,
  OrderHistory: details.OrderHistory,
  GiftFrom :details.GiftFrom,
});
    
} catch (e) {
  console.log("user update fail---",e);
UnableToUpdate();
}
};

/**
 * Update user data
 */
const updateUserData = async (uid, details) => {
  try {
    await firestore
      .collection('users')
      .doc(uid)
      .update({ ...details, updatedDate: currentServerTime });
  } catch (e) {
      console.log("user update fail---",e);
    UnableToUpdate();
  }
};

/**
 * Update user data
 */
 const updateLeadsData = async (uid, details) => {
    try {
        await firestore
          .collection('leads')
          .doc(uid).set({
            ...details
          });
          LeadCreatedSuccessFully();
      } catch (e) {
          console.log("lead insert err-",e)
        UnableToUpdate();
      }
};
/**
 * Add new subscriber
 */
const addNewSubscriber = async (email) => {
  try {
    const addSubscriber = firebaseFunctions.httpsCallable('addSubscriber');
    await addSubscriber({ email });
    Message.success('Added successfully.');
    return true;
  } catch (e) {}
};

/**
 * Log out.
 * @returns {Promise<boolean|*>}
 */
const signOut = async () => {
  try {
    await auth.signOut();
    return true;
  } catch (e) {
    console.log(e);
  }
};

export {
  signInWithGoogle,
  addNewSubscriber,
  updateUserData,
  fetchUserDataById,
  signOut,
  requestOTP,
  confirmCode,
  updateLeadsData,
  updateGiftData,
};
