import React ,{useState,useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import CakeIcon from '../../assets/images/temp-cake.jpg';
import { updateUserData } from '../../operations/onboarding';
import { PromocodeSuccessMessage, PromocodefailedMessage } from '../../operations/utils';
// import { useSelector } from 'react-redux';
import { months } from '../../utils';
import {fetchPromoCodes} from "../../operations/products"
import { useDispatch, useSelector } from 'react-redux';
import Promocodes from "./promoCode"
// import { currencySymbol } from './../../utils';


const useStyles = makeStyles((theme) => ({
  rightSection: {
    backgroundColor: 'rgba(250, 247, 243, 0.7)',
    padding: '0!important',
    display: 'block',
  },
  rightHeading: {
    fontSize: 24,
    lineHeight: '36px',
    padding: '80px 0 56px',
    textAlign: 'center',
    color: '#535B65',
    borderBottom: '1px solid #D8D8D8',
  },
  productSection: {
    padding: '70px 40px 40px 48px',
  },
  productImage: {
    width: 140,
    height: 100,
    objectFit: 'cover',
    borderRadius: 6,
  },
  productName: {
    fontSize: 20,
    lineHeight: '24px',
    color: '#535B65',
  },
  subTotal: {
    padding: '40px 48px',
    float: 'right',
  },
  totalPrice: {
    float: 'right',
  },
  shipping: {
    color: '#FCAB53',
    float: 'right',
  },
  bottomText: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    opacity: 0.8,
    fontWeight: 400,
  },
  divider: {
    border: '1px solid #D8D8D8',
    margin: '0 40px',
  },
}));


//payment screen

const CartSummary = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let {
    subscriptions = [],
    products = [],
    snacks = [],
    grandTotal = 0,
    shippingTotal = 0,
    tax = 0,
    subTotal = 0,
    currencySymbol
  } = useSelector((state) => state.cart);

  // let currentState = useSelector((state) => state.cart);
  const [promoCodetoggle,setpromoCodetoggle] = useState(true);
  const [promoCodehandler,setpromoCodehandler] = useState(true);

  const [promocodes, setPromoCode] = useState([]);
    const getpromocodelist = async () => {
        const result = await fetchPromoCodes();
        setPromoCode(result);
    };

    useEffect(() => {
        
        async function fetchPromoCodes() {
            await getpromocodelist();
        }
        fetchPromoCodes();
    }, []);

    useEffect(() => {
      setgrandTotalSavedvalue(grandTotal)
      setgrandTotalvalue(grandTotal);
  }, [subTotal]);



    const promocodeslist = promocodes.filter((item) => item.active === true)
    let validpromo = false;
    let discountamount = 0;
    let enteredpromocode = "";
    let type = "";
  let plan_id = "";
  let productType = "";
    const validatepromocode = async (promocode) => {

      validpromo = false;
         await promocodeslist.map(function (object, i) {
                if (object.code == promocode) {
                     
                     discountamount = object.amount;
                    enteredpromocode = object.code;
                  type = object.type;
                  plan_id = object.planID?object.planID:"";
                  productType = object.productType ? object.productType : "";
                  
                  console.log(discountamount, enteredpromocode, type, plan_id, productType);
                
                  if (productType === "Annual Plan" && subscriptions && subscriptions.length) {
                    validpromo =  subscriptions[0].title == "Annual Plan"&&true;
                                     
                  }
                  if (productType === "Monthly Plan" && subscriptions && subscriptions.length) {
                    validpromo =  subscriptions[0].title == "Monthly Plan"&&true;                
         
                  }
                  if (productType === "Product" && products&&products.length) {
                    console.log("procusts condition verified");
                    validpromo = true;
                  }
                  if (productType === "Snacks" && snacks &&snacks.length ) {
                    validpromo = true;
                  }
                  if (parseInt (discountamount ) > parseInt (grandTotalSavedvalue)) {
                    validpromo = false;
                  }

                };
            })
            
        if(validpromo) {
          PromocodeSuccessMessage();
          setpromoCodetoggle(false);
          setpromoCodehandler(!promoCodehandler);
          props.promohandle(promoCodehandler);
          props.handlediscount({discountamount,enteredpromocode,type,plan_id});
          setgrandTotalvalue(grandTotalSavedvalue-discountamount);
          setDiscount(discountamount);

       await addPromoCoder(discountamount,grandTotal ,subscriptions , products ,snacks ,grandTotal ,
          shippingTotal ,
          tax ,
          subTotal ,
          enteredpromocode);
        }
        else{
          PromocodefailedMessage();
        }
    }

    const addPromoCoder = async (discountamount,grandtotal ,subscription , products ,snacks ,grandTotal ,shippingTotal ,tax ,subTotal,enteredpromocode) => {
     
    //  await dispatch(CartActions.addPromoCode({discountamount,grandtotal ,subscriptions , products ,snacks ,grandTotal ,
    //     shippingTotal ,
    //     tax ,
    //     subTotal}))
      console.log("promocode for subscription----", subscription);

      await updateUserData(props.user.uid, {
        cart: {
          grandTotal: subTotal -discountamount,
          subscriptions,
          products ,
          snacks ,
          enteredpromocode,
          // ...currentState,
        },
      })

      // dispatch(CartActions.addPromoCode({discountamount,grandtotal ,subscriptions , products ,snacks ,grandTotal ,
      //   shippingTotal ,
      //   tax ,
      //   subTotal}));
     
    };

    const [grandTotalvalue,setgrandTotalvalue] = useState(grandTotal);
    const [grandTotalSavedvalue,setgrandTotalSavedvalue] = useState(grandTotal);
    const [discount,setDiscount] = useState(0);

    useEffect(()=>{
      setgrandTotalvalue(subTotal);
    },
    [])

    
    

  return (
    <Grid container item xs={12} sm={5} className={classes.rightSection}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body2" className={classes.rightHeading}>
          Your Order Summary
        </Typography>
      </Grid>
      

      {subscriptions.map((item, index) => (
        <Item item={item} key={`subs${index}`} />
      ))}

      {products.map((item, index) => (
        <Item item={item} key={`prods${index}`} />
      ))}

      {snacks.map((item, index) => (
        <Item item={item} key={`snacks${index}`} />
      ))}
      <hr className={classes.divider} />

    
      <Grid container item xs={12} sm={12} className={classes.subTotal}>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Sub total
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            {currencySymbol}{subTotal.toFixed(2)}
          </Typography>
        </Grid>


        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Discount
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            {currencySymbol}{discount&&discount.toFixed(2)}
          </Typography>
        </Grid>

        {/* <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Shipping
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography
            variant={'h2'}
            color={'primary'}
            className={classes.shipping}>
            {currencySymbol}{shippingTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Tax (IGST 18%)
          </Typography>
        </Grid>
        
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            {currencySymbol}{tax.toFixed(2)}
          </Typography>
        </Grid> */}
      </Grid>
      <hr className={classes.divider} />
    
      <Grid container item xs={12} sm={12} className={classes.subTotal}>
        <Grid item xs={8} sm={8}>
          <Typography variant={'h2'} className={classes.bottomText}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant={'h2'} className={classes.totalPrice}>
            {currencySymbol}{grandTotalvalue.toFixed(2)}
          </Typography>
        </Grid>
       
       { props.step===2&&<Promocodes validate= {validatepromocode}/>}
      </Grid>
     
    </Grid>
  );
};

const Item = ({ item }) => {
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  const { month } = item;

  const isPreorder = month ? !(month === months[new Date().getMonth()]) : false;

  return (
    <Grid
      container
      spacing={2}
      item
      xs={12}
      sm={12}
      className={classes.productSection}>
      <Grid item xs={4} sm={4}>
        <Badge badgeContent={item.qty} color="primary">
          <img
            src={item.image || CakeIcon}
            alt="Item"
            className={classes.productImage}
          />
        </Badge>
      </Grid>
      <Grid container item xs={5} sm={5}>
        <Typography variant={'h2'} className={classes.productName}>
          {item.name}
        </Typography>
        {isPreorder && (
          <Typography
            style={{
              fontSize: 14,
              color: '#faab53',
            }}>{`Note: Item will be shipped on 30th ${
            months[new Date().getMonth()]
          } `}</Typography>
        )}
      </Grid>
      <Grid item xs={3} sm={3}>
        <Typography variant={'h2'} className={classes.totalPrice}>
          {currencySymbol}{(item.price - (item.discount_price ? item.discount_price : 0)) * item.qty}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CartSummary;
