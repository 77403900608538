import { createAction } from '../../redux/actionSaga';

export const GlobalActionTypes = {
  SET_CART_STATUS: 'Global/SET_CART_STATUS',
  SET_OTP_RESPONSE: 'Global/SET_OTP_RESPONSE',
  SET_OTP_SENT: 'Global/SET_OTP_SENT',
  RESET_GLOBALS: 'Global/RESET_GLOBALS',
  SET_LOGGED_IN_STATUS: 'Global/SET_LOGGED_IN_STATUS',
  SET_CURRENT_KIT: "Global/SET_CURRENT_KIT",
  SET_CURRENT_SNACK: "Global/SET_CURRENT_SNACK",
};

const GlobalActions = {
  setCartStatus: createAction(GlobalActionTypes.SET_CART_STATUS),
  setOtpResponse: createAction(GlobalActionTypes.SET_OTP_RESPONSE),
  setOtpSent: createAction(GlobalActionTypes.SET_OTP_SENT),
  resetGlobals: createAction(GlobalActionTypes.RESET_GLOBALS),
  setLoggedInStatus: createAction(GlobalActionTypes.SET_LOGGED_IN_STATUS),
  setCurrentKit: createAction(GlobalActionTypes.SET_CURRENT_KIT),
  setCurrentSnack: createAction(GlobalActionTypes.SET_CURRENT_SNACK),
};

const initialState = {
  loading: false,
  cartStatus: false,
  otpResponse: null,
  otpSent: false,
  loggedInStatus: false,
  currentKit: null,
  currentSnack: ""
};

export const GlobalReducer = (state = initialState, action) => {
  switch (action.type) {

   
    case GlobalActions.setCurrentKit.SUCCESS: {
      return {
        ...state,
        currentKit: action.payload,
        loading: false
      }
    }

    case GlobalActions.setCartStatus.SUCCESS: {

      return {
        ...state,
        cartStatus: action.payload,
        loading: false,
      };
    }
    case GlobalActions.setOtpResponse.SUCCESS: {
      return {
        ...state,
        otpResponse: action.payload,
        loading: false,
      };
    }
    case GlobalActions.setOtpSent.SUCCESS: {
      return {
        ...state,
        otpSent: action.payload,
        loading: false,
      };
    }
    case GlobalActions.setLoggedInStatus.SUCCESS: {
      return {
        ...state,
        loggedInStatus: action.payload,
        loading: false,
      };
    }
    case GlobalActions.resetGlobals.SUCCESS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default GlobalActions;
