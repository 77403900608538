import React from 'react';
import "../../components/SummerCamp/index.css"

const DownloadApp = () => {
  
  return (
   
    <section className="sectionEight">
    <div className="BGTop" >
        <img src={require("../../assets/images/summarCamp/Bg-top.png")} alt="bg-top" />
    </div>
    <div className="BGBottom">
        <img src={require("../../assets/images/summarCamp/Bg-bottom.png")} alt="bg-bottm"/></div>


    <div className="content-left">
        <img src={require("../../assets/images/summarCamp/MobileFrame.png")} alt="mobile"/>
    </div>
    <div className="content-right">
        <h6 className="sub-title">Download App</h6>
        <h4 className="title" style={{letterSpacing: 0}}>Download the Orange Figs App For Free!</h4>
        <p style={{fontWeight: 100}}>An app that provides a perfect blend of engaging video recipes by best bakers across the globe and DO IT YOURSELF kit to kid’s door step.</p>
        <div className="d-flex mtop-30">
            <a href="https://play.google.com/store/apps/details?id=com.orangefigs" target="_blank">
                <img src={require("../../assets/images/summarCamp/Google.png")} alt="google"/>
            </a>
            <a href="https://apps.apple.com/us/app/orange-figs/id1555526421" target="_blank">
                <img src={require("../../assets/images/summarCamp/Apple Store.png")} alt="app-store"/>
            </a>
        </div>
    </div>
</section>
  );
};

export default DownloadApp;
