import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../containers/Home';
import Subscribe from '../containers/Subscribe';
import Account from '../containers/Account';
import ProductListing from '../containers/ProductListing';
import ProductDetails from '../containers/ProductDetails';
import ReadyToEat from '../containers/ReadyToEat';
import { Paths } from './routePaths';


import ReadyToEatDetails from '../containers/ReadyToEatDetails';
import CompleteFAQ from '../components/FAQ';
import PrivacyPolicy from '../components/PrivacyPolicy';
import AboutUs from '../containers/AboutUs';
import TermsOfUse from '../components/TermsOfUse';
import ReturnPolicy from '../components/ReturnPolicy';
import OrderSuccess from '../containers/OrderSuccess';
import ProductDetailsLanding from '../containers/ProductDetailsLanding';
import ReadyToEatDetailsLanding from '../containers/ReadyToEatDetailsLanding';
import SummerCampUSA from '../containers/SummerCampUSA';
import SummerCampHome from '../containers/SummerCampHome';
import Ordertracking from "../containers/OrderTracking"
import IISSchoolContainer from '../containers/Schools/IISSchool';
import Support from '../components/Support';
/**
 * Import Containers here
 */

const Routes = ({ user }) => {

  return (
    <Switch>
      <Route exact={true} path={Paths.Home} component={Home} />
      <Route exact={true} path={Paths.SchoolIIS} component={IISSchoolContainer} />
      <Route exact={true} path={Paths.SummerCampUSA} component={SummerCampUSA} />
      <Route exact={true} path={Paths.SummerCampHome} component={SummerCampHome} />
      <Route exact={true} path={Paths.SummerCamp} component={Home} />
      <Route exact={true} path={Paths.Subscribe} component={Subscribe} />
      <Route
        exact={true}
        path={Paths.Account}
        component={() => <Account user={user} />}
      />
      <Route
        exact={true}
        path={Paths.ProductListing}
        component={ProductListing}
      />
      <Route
        exact={true}
        path={Paths.ProductDetails}
        component={ProductDetails}
      />
      <Route
        exact={true}
        path={Paths.ProductDetailsLanding}
        component={ProductDetailsLanding}
      />
      <Route exact={true} path={Paths.ReadyToEat} component={ReadyToEat} />
      <Route
        exact={true}
        path={Paths.ReadyToEatDetails}
        component={ReadyToEatDetails}
      />

      <Route
        exact={true}
        path={Paths.ReadyToEatDetailsLanding}
        component={ReadyToEatDetailsLanding}
      />

      <Route exact={true} path={Paths.CompleteFAQ} component={CompleteFAQ} />
      <Route exact={true} path={Paths.orderTracking} component={Ordertracking} />
      <Route
        exact={true}
        path={Paths.PrivacyPolicy}
        component={PrivacyPolicy}
      />
      <Route exact={true} path={Paths.AboutUs} component={AboutUs} />
      <Route exact={true} path={Paths.TermsOfUse} component={TermsOfUse} />
      <Route exact={true} path={Paths.ReturnPolicy} component={ReturnPolicy} />
      <Route exact={true} path={Paths.OrderSuccess} component={OrderSuccess} />
      <Route exact={true} path={Paths.support} component={Support} />
    </Switch>
  );
};

export default Routes;
