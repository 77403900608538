import IISSchool from '../../components/Schools/IIS';
import React from 'react';
import Cart from '../../components/Cart';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

const IISSchoolContainer = () => {
  const dispatch = useDispatch();
  const setOpenCart = (val) => {
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: val });
  };
  let { cartStatus } = useSelector((state) => state.global);

  return (
    <div>
      <IISSchool/>
      <Cart openCart={cartStatus} toggleCart={setOpenCart} />
    </div>
  );
};

export default IISSchoolContainer;
