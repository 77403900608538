import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonroot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container:{
      overflow:"hidden",
  },
  inner:{
    overflow:"hidden",
  },
  child :{
      float : "left",
  },
  promoInput:{
      color : "green",
  },
}));

export default function Promocodes(props) {
  const classes = useStyles();

  const [promoCode,setpromoCode] = React.useState("");

  const handlechange=(e)=>{

    const promocode = e.target.value
      setpromoCode(promocode.toLowerCase());
      console.log(e.target.value);
  }

  const checkpromocode =()=>{
      console.log("checkpromocode inside promocode is called");
    props.validate(promoCode)
  }

  return (
     <div className={classes.container} >
  <div className={classes.inner} id="inner">
    <div className={classes.child}>
        <form className={classes.root} noValidate autoComplete="off">
      <TextField className={classes.promoInput} onChange={(e)=>{handlechange(e)}} id="standard-basic" label="Promo Code" />
    </form></div> 
    <div className={classes.child}>
        <div className={classes.buttonroot}>
        <Button variant="outlined" color="primary" onClick = {checkpromocode}>
          APPLY
        </Button>
        </div>
        </div>
  </div>
</div>
  );
}

