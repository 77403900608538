import React, { useEffect, useState } from 'react';
import ContactUs from '../../components/ContactUs';
// import FAQ from './FAQ';
import KitContents from './kitContents';
import HeroSubscribe from './hero';
import AdditionalDetails from './details';
import { fetchSubscriptions } from '../../operations/subscriptions';
import Layout from '../../components/Layout';
import CommonCta from '../../components/Cta';
import ThisMonthsKits from './thisMonthsKits';
import { fetchProducts } from '../../operations/products';
import { Paths } from '../../routes/routePaths';
// import ZipcodeVerify from '../../components/Zipcode';
import DownloadApp from '../Home/downloadApp';

const Subscribe = () => {
  const [subscriptions, setSubscriptionData] = useState([]);
  const [products, setProducts] = useState([]);

  const getSubscriptions = async () => {
    const result = await fetchSubscriptions();
    setSubscriptionData(result);
  };

  const getProducts = async () => {
    const result = await fetchProducts();
    setProducts(result);
  };

  useEffect(() => {
    async function fetchData() {
      await getSubscriptions();
      await getProducts();
    }
    fetchData();
  }, []);


  return (
    <Layout whiteBG={true}>
      <HeroSubscribe subscriptions={subscriptions} />
      <AdditionalDetails />
      <KitContents />
      {/* <ThisMonthsKits products={products}/> */}
      {/* <FAQ /> */}
      {/* <ZipcodeVerify/> */}
      <DownloadApp/>
      <CommonCta
        title={'Looking for your favorite items?'}
        btnText={'Shop Single Kits'}
        onClick={Paths.ProductListing}
      />
      <ContactUs />
    </Layout>
  );
};

export default Subscribe;
