import axios from 'axios';

const URl = "https://cultchampspvtltd.myfreshworks.com/crm/sales/api/contacts";
const token = `Token token=DIebyus8mF98ncvZeQrmcQ`;

export function apiRequest(data) {
    console.info("NotificationService:: URl", URl);
    try {
        const config = {
            headers: {
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "Content-Type, Authorization",
                // "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Origin":  'https://qa-orangefigs.firebaseapp.com',
                'Authorization': token,//`Token token=DIebyus8mF98ncvZeQrmcQ`,
                "Content-Type": "application/json"
            },
            timeout: 1000,
        };
        axios.post(
            URl,
            data,
            config,
        )
            .then((res) => {
                console.info("NotificationService:: Notification send properly");
            })
            .catch((err) => {
                console.error("NotificationService:: err ", err);
            });
    } catch (error) {
        console.error(error);
    }
}
// export default apiRequest;