import React, { useEffect } from 'react';
import {  useSelector } from 'react-redux';
import {fetchUsers} from "../../operations/OrderTracking"
import { useHistory } from 'react-router-dom';
const axios = require('axios');
const OrderTracking = (props) => {

    // const history = useHistory();

let { User } = useSelector((state) => state.global);

const  fetchdata = async  ()=>{
   
  const orders =  await fetchUsers(User.uid);
  console.log("orders---",orders);

} 
const fetchtracking = () =>{

    console.log(User);
axios.get('https://apicouriers.getgologistics.com/search/couriers/shipment/track/3315310518210')
  .then(function (response) {
    // handle success
    console.log("axios response",response);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });
}

useEffect(()=>{
    fetchdata() 
},[])

  return (
    <div>
     
     <button onClick={fetchtracking}>click</button>


    </div>
  );
};

export default OrderTracking;
