import { firestore } from '../../firebase';

const fetchUsers = async (uid) => {

    try {
      const response = await firestore.collection('users').doc(uid).get();
      
        return { ...response.data(), id: uid };
    
  
    } catch (error) {
      console.log("users list for uid", error);
      console.warn(error)
    }
  
  }


  export { fetchUsers };
