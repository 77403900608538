import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ClampLines from 'react-clamp-lines';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 760 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 760, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const ProductsCarousel = (props) => {

    const [IsShown,setIsShown] = React.useState(-1);
    const [CurrentSlider,setCurrentSlider] = React.useState(1);
 
    return (

        <section id="carousel" >
        <div>

        <section id="carousel" class="sectionThree backcolor" >
             <h2 className="carousel-title">Hey Kids!! We’re Bringing Summer Camp Products To Your Home!!!</h2>
           </section>
             
        <div className="row backcolor" style={{marginLeft:'6%', marginRight:'6%'}}>
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="carousel slide multi-item-carousel" id="theCarousel">
                    <div className="carousel-inner">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            afterChange={(previousSlide, { currentSlide, onMove }) => { 
                                                 
                                setCurrentSlider((currentSlide%3)+1);
                              }}
                            >
                            {
                            props &&
                                props.data.map((product,index) => {
                                    const { image, name, description } = product;
                                    
                                    return (
                                        <div className="item active" >
                                            <div className=" itemsize" onMouseEnter={() => {setIsShown(index);
                                                    console.log("hover working")}}
                                                    onMouseLeave={() => setIsShown(-1)}>
                                                <a href="#1">
                                                    <img src={image} alt={name} className={IsShown===index?"img-responsive ":"img-responsive"} 
                                                      />
                                                </a>
                                                {<div className="subPara img_hover">
                                                    
                                                    <h6>{name}</h6>
                                                    {/* <p>{description}</p> */}
                                                    <ClampLines text={description}
                                                        id="custom"
                                                        moreText= "Read more"
                                                        lessText="Collapse"
                                                        className="clamplines_para"
                                                        ellipsis="..."
                                                        lines={2}
                                                    />
                                                </div>}
                                            </div>
                                        </div>
                                    );
                                })}
                        </Carousel>
                        {/* <div className="rowCounter">{CurrentSlider} of 12</div> */}
                        
                    </div>
                    <div className="dots" >
                       
                        <ul>
                            <li> <div className={CurrentSlider===1?"regular":"  slected"}></div> </li>
                            <li><div className={CurrentSlider===2?"regular":" slected"}></div> </li>     
                            <li>  <div className={CurrentSlider===3?"regular":"  slected"}></div></li>
                        </ul>
                        
                              </div>
                </div>
            </div>
        </div>
   
   </div>
   </section>
    );
};

export default ProductsCarousel;
