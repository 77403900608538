import React from 'react';
import Carousel from './carousel';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';
import { useDispatch } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import GlobalActions from '../../redux/reducer/globalReducer';
import ServingIcon from '../../assets/images/ico-serving-size.png';
import { months } from '../../utils';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '120px 0',
  },
  icon: {
    height: 72,
    width: 'auto',
  },
  smallIcon: {
    width: 14,
    height: 'auto',
    marginRight: 8,
  },
  month: {
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#535B65',
    fontWeight: 400,
    paddingTop: 0,
  },
  productName: {
    color: '#FAAB53',
    fontSize: 40,
    lineHeight: '56px',
    fontFamily: 'HappySchool',
    paddingTop: 0,
    maxWidth: 504,
    marginBottom: 24,
  },
  price: {
    fontSize: '28px',
    lineHeight: '36px',
    color: '#535B65',
    alignItems: 'center',
    display: 'flex',
  },
  desc: {
    fontSize: 20,
    lineHeight: '28px',
    color: '#535B65',
    marginTop: 40,
    maxWidth: 504,
    fontWeight: 400,
  },
  heading: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#535B65',
    opacity: 0.9,
    textTransform: 'uppercase',
  },
  info: {
    fontSize: 16,
    color: '#535B65',
  },
  ecLink: {
    fontSize: 21,
    lineHeight: '24px',
    marginLeft: 8,
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#FA4A39',
  },
  btnSubmit: {
    borderRadius: '36px',
    padding: '16px 64px',
    fontSize: '20px',
  },
}));

const ProductDetailsHero = ({ product }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.KIT }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
  };

  const {
    month,
    name,
    price,
    description,
    serves,
    nutrition,
    imageDetails,
  } = product;

  const isPreorder = month ? !(month === months[new Date().getMonth()]) : false;

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Carousel image={imageDetails} />
          </Grid>
          <Grid container item xs={12} sm={7}>
            <Grid item xs={12} sm={12}>
              <Typography variant={'h2'} className={classes.productName}>
                {name}
              </Typography>
              <Grid
                container
                item
                xs={12}
                sm={12}
                direction={'row'}
                alignItems={'center'}>
                <Grid item xs={4} sm={4}>
                  <Typography variant={'h2'} className={classes.price}>
                    {currencySymbol}{price}
                    <span style={{ fontSize: 12, marginLeft: 5 }}>
                      {' '}
                      + taxes
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Button
                      theme
                      className={classes.btnSubmit}
                      onClick={() => addToCart(product)}
                      variant={'contained'}
                      color={'primary'}
                      disableElevation>
                      {isPreorder ? 'Preorder Now' : 'Add To Cart'}
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant={'h2'} className={classes.desc}>
                  {description}
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  borderTop: '1px solid #535B65',
                  borderBottom: '1px solid #535B65',
                  maxWidth: 504,
                  margin: '24px 0',
                  padding: '17px 24px',
                }}>
                <Grid item xs={4} sm={4}>
                  <Typography variant={'h2'} className={classes.heading}>
                    Bake time
                  </Typography>
                  <Typography variant={'body2'} className={classes.info}>
                    45 mins
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant={'h2'} className={classes.heading}>
                    Serves
                  </Typography>
                  <Typography variant={'body2'} className={classes.info}>
                    <img
                      className={classes.smallIcon}
                      src={ServingIcon}
                      alt={'Serving'}
                    />
                    {serves}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant={'h2'} className={classes.heading}>
                    Nutrition
                  </Typography>
                  <Typography variant={'body2'} className={classes.info}>
                    {nutrition ? nutrition.calories : null} kcal/serving
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant={'body2'} className={classes.desc}>
                  Buy our kits often?
                  <Link
                    to={Paths.Subscribe}
                    variant={'h2'}
                    className={classes.ecLink}>
                    Time to subscribe, now.
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProductDetailsHero;
