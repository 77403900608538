import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Kit from './kit';
import { useDispatch } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import GlobalActions from '../../redux/reducer/globalReducer';
import moment from 'moment';
import { Link } from 'react-router-dom';
import KitIcons from '../../assets/images/ico-single-hero.svg';
import { months } from '../../utils/index';

const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(to bottom, #FAF7F3 0%, #FAF7F3 700px, #FFFFFF 700px, #FFFFFF 100%)',
  },
  placeHolderImg: {
    height: 328,
    width: 328,
    borderRadius: 208,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  preOrderSection: {
    padding: '96px 0 120px',
    background: 'rgba(59, 199, 213, 0.15)',
  },
  preOrderItems: {
    borderRadius: 24,
    background: '#ffffff',
    maxWidth: 504,
    padding: '0!important',
    textAlign: 'center',
    marginBottom: 40,
  },
  kitImg: {
    height: 336,
    width: 504,
    borderRadius: '24px 24px 0 0',
  },
  kitName: {
    fontSize: 28,
    lineHeight: '36px',
    color: '#535B65',
    opacity: 0.9,
    padding: 48,
  },
});

const ListingHero = ({ products }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Dates
  // const currentDate = new Date().getTime();

  // const createdDate = products.map((kit) => {
  //   let dates = kit.createdDate.toDate().getTime();
  //   return dates;
  // });

  const preOrderDate = moment().format('D');

  const preOrderShow = preOrderDate > 14 && preOrderDate < 26;

  // Months
  // const currentMonth = moment().format('MMMM');
  const nextMonth = moment().add(1, 'months').format('MMMM');

   
  const currentMonthKits = products.filter(
    (kit, idx) => kit.month === months[new Date().getMonth()]&&kit.availability==="true"
  );

  // const currentMonthKits = products; 
// availability "true" 
    
   

  const kitsDisplayed = currentMonthKits.sort(
    (a, b) =>
      parseInt(b.createdDate.toDate().getTime()) -
      parseInt(a.createdDate.toDate().getTime())
  );

  const preOrderKits = products.filter(
    (kit) => kit.month === nextMonth && preOrderShow
  );

  // Add to cart
  const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.KIT }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
  };


  return (
    <div className={classes.root} style={{ padding: '120px 0' }}>
      <Container fixed>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center">
          <Grid
            item
            xs={12}
            sm={5}
            style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.placeHolderImg}>
              <img style={{ height: '164px', width: '164px' }} src={KitIcons} alt="Kits" />
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 40,
                lineHeight: '56px',
                fontFamily: 'HappySchool',
              }}>
              Single Kits
            </Typography>
            <Typography
              variant={'h2'}
              style={{
                color: '#535B65',
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '28px',
                marginBottom: 20,
              }}>
              Browse our range of easy-to-do, fun baking kits with unique,
              chef-designed recipes.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ margin: '80px 0' }}>
          {kitsDisplayed && kitsDisplayed.length
            ? kitsDisplayed.map((kit, idx) => (
              <Grid item xs={12} sm={4} key={idx}>
                <Kit details={kit} addToCartHandler={addToCart} />
              </Grid>
            ))
            : null}
        </Grid>
      </Container>
      {preOrderKits && preOrderKits.length ? (
        <div className={classes.preOrderSection}>
          <Container fixed>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: 'space-evenly' }}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ textAlign: 'center', marginBottom: 50 }}>
                <Typography
                  variant={'h2'}
                  style={{
                    color: '#535B65',
                    fontSize: 40,
                    lineHeight: '56px',
                    fontFamily: 'HappySchool',
                  }}>
                  Pre-order Kits
                </Typography>
                <Typography
                  variant={'h2'}
                  style={{
                    color: '#535B65',
                    fontSize: 24,
                    lineHeight: '28px',
                  }}>
                  Available now for pre-ordering. All orders will be shipped on
                  30th of the month.
                </Typography>
              </Grid>
              {preOrderKits && preOrderKits.length
                ? preOrderKits.map((kit, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={idx}
                    className={classes.preOrderItems}>
                    <img
                      src={kit.image}
                      alt="Kit"
                      className={classes.kitImg}
                    />
                    <Typography variant="h2" className={classes.kitName}>
                      <Link
                        to={`baking-kits/products/${kit.slug}`}
                        style={{ color: '#535B65', textDecoration: 'none' }}>
                        {kit.name}
                      </Link>
                    </Typography>
                  </Grid>
                ))
                : null}
            </Grid>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export default ListingHero;
