import React, { useEffect } from 'react';
import ContactUs from '../../components/ContactUs';
import CommonCta from '../../components/Cta';
import DownloadApp from './downloadApp';
import Hero from './hero';
import HowItWorks from './howItWorks';
import KitContents from './kitContents';
import Benefits from './benefits';
import Layout from '../../components/Layout';
import { Paths } from '../../routes/routePaths';
import FreshChat from 'react-freshchat';
import { fetchtestPromoCode } from '../../operations/products';
import Gift from "./Gift"
import { useDispatch } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';



const WordpressParser = require('rss-parser');


const test = async () => {
  const result = await fetchtestPromoCode('testpromocode');
  console.log(result);
}



const Home = () => {

  const onload = async () => {
   

  //  await axios.get('http://65.1.252.80/feed/')
  //   .then(function (response) {
  //     // handle success
  //     console.log(response);
  //   })
  //   .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //   })

   }

   const dispatch = useDispatch();

  useEffect(() => {
    //  getRssData();
    dispatch(CartActions.setCounrty.success({country:'India',currencySymbol:'₹',homePath:'/' }));
  }, [])



  return (
    <div>

      <Layout whiteBG={true}>
        {/* <button onClick={test}>chatbot app</button> */}
        {/* <iframe src="http://65.1.252.80/" title="W3Schools Free Online Web Tutorials"></iframe> */}

        <Hero />
        <HowItWorks />
        <KitContents />
        <Gift />

        <Benefits />

        {/* <DownloadApp /> */}
        <CommonCta
          title={'Still undecided?'}
          btnText={'Browse Our Single Kits'}
          onClick={Paths.ProductListing}
        />
        <DownloadApp />
        <ContactUs />

      </Layout>
    </div>
  );
};

export default Home;
