import React, {  useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';


import Link from '@material-ui/core/Link';
import Back from '../../assets/images/cart-back.png';
import {
  confirmCode,
  requestOTP,
} from '../../operations/onboarding';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./AccountLogin.css"
import firebase from 'firebase/app';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
  },
  icon: {
    height: 22,
    width: 'auto',
    marginRight: 10,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Andes',
    
  },
  headingLeft: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 15,
    display: 'flex',
  },
  headingRight: {
    color: '#535B65',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    float: 'right',
    marginBottom: 15,
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '30px 0 15px',
  },
  btnGoogle: {
    borderRadius: '32px',
    padding: '12px 92px',
    margin: '12px 0',
    fontSize: '1rem',
    border: '1px solid #C4C4C4',
    color: '#000000',
    textTransform: 'none',
  },
  googleIcon: {
    height: 'auto',
    width: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
}));

const AccountLogin = ({ history, handleNext }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  let cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  const country = cart.country;
  let {  otpSent } = useSelector((state) => state.global);

  // const GoogleIcon = (
  //   <Icon className={classes.googleIcon}>
  //     <img src={Google} alt="Google" style={{ height: 'auto', width: 24 }} />
  //   </Icon>
  // );

  const captchaRef = React.useRef(null);

  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');

//   console.log(phone);

  const signInWithPhone = async () => {
    if (phone.length) {
      const res = await requestOTP(
        // `+91${phone}`,
        `+${phone}`,
        new firebase.auth.RecaptchaVerifier(captchaRef.current, {
          size: 'invisible',
        })
      );
      dispatch({ type: GlobalActions.setOtpResponse.SUCCESS, payload: res });
      dispatch({ type: GlobalActions.setOtpSent.SUCCESS, payload: true });
    }
  };

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12}>
            {!otpSent || window.otpResponse === undefined ? (
              <>
                <Typography variant={'h2'} className={classes.headingLeft}>
                  Sign in/ Register to continue
                </Typography>
                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <Typography variant={'h2'} className={classes.headingRight}>*/}
                {/*    Already have an account?{' '}*/}
                {/*    <Link href="#" onClick={(event) => event.preventDefault()}>*/}
                {/*      Sign in*/}
                {/*    </Link>*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    style={{ margin: '12px 0' }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    onChange={(event) => setPhone(event.target.value)}
                    InputLabelProps={{
                      style: {
                        color: '#6B6B6B',
                      },
                    }}
                  /> */}
                  <PhoneInput
                    country={ country === 'India' ? 'in' :'us'}
                    value={phone}
                    enableSearch = {true}
                    placeholder={"Enter Phone Number"}
                    required={true} 
                   autoFocus= {true}
                    onChange={phone => setPhone( phone )}
                    />
                  <div ref={captchaRef} />
                </Grid>
                <Grid item xs={12}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Button
                      fullWidth
                      theme
                      className={classes.btnSubmit}
                      onClick={() => signInWithPhone()}
                      variant={'contained'}
                      color={'primary'}
                      disableElevation>
                      Next
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={10}>
                  <Typography variant={'h2'} className={classes.headingLeft}>
                    <Link
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch({
                          type: GlobalActions.setOtpResponse.SUCCESS,
                          payload: null,
                        });
                        dispatch({
                          type: GlobalActions.setOtpSent.SUCCESS,
                          payload: false,
                        });
                      }}>
                      <img
                        src={Back}
                        alt="Back"
                        style={{ height: 24, width: 'auto', marginRight: 16 }}
                      />
                    </Link>
                    Enter the verification code sent to your mobile
                  </Typography>
                </Grid>
                {/*<Grid item xs={12} sm={2}>*/}
                {/*  <Typography variant={'h2'} className={classes.headingRight}>*/}
                {/*    <Link href="#" onClick={(event) => event.preventDefault()}>*/}
                {/*      Resend code*/}
                {/*    </Link>*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={12} lg={12} md={12}>
                  <TextField
                    fullWidth
                    label="Enter the verification code"
                    variant="outlined"
                    style={{ margin: '12px 0' }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    value={otp}
                    onChange={(event) => setOtp(event.target.value)}
                    InputLabelProps={{
                      style: {
                        color: '#6B6B6B',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiThemeProvider theme={buttonFont}>
                    <Button
                      fullWidth
                      theme
                      className={classes.btnSubmit}
                      onClick={async () => {
                        if (otp.length === 6) {
                          const res = await confirmCode(otp, cart);
                          if (res) {
                            dispatch({
                              type: GlobalActions.setLoggedInStatus.SUCCESS,
                              payload: true,
                            });
                            dispatch({
                              type: GlobalActions.setOtpResponse.SUCCESS,
                              payload: null,
                            });
                            dispatch({
                              type: GlobalActions.setOtpSent.SUCCESS,
                              payload: false,
                            });
                            window.location.reload();
                          }
                        }
                      }}
                      variant={'contained'}
                      color={'primary'}
                      disableElevation>
                      Next
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </>
            )}

            {/*<Grid*/}
            {/*  item*/}
            {/*  xs={12}*/}
            {/*  sm={12}*/}
            {/*  style={{ textAlign: 'center', margin: 12 }}>*/}
            {/*  <Typography variant={'body2'}>or</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} lg={12} md={12}>*/}
            {/*  <Button*/}
            {/*    fullWidth*/}
            {/*    theme*/}
            {/*    className={classes.btnGoogle}*/}
            {/*    onClick={async () => {*/}
            {/*      const res = await signInWithGoogle(cart);*/}
            {/*      if(res){*/}
            {/*        dispatch({type: GlobalActions.setLoggedInStatus.SUCCESS, payload: true})*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    variant={'outlined'}*/}
            {/*    color={'inherit'}*/}
            {/*    startIcon={GoogleIcon}*/}
            {/*    disableElevation>*/}
            {/*    Continue with Google*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>

          {/*<Grid item xs={12} sm={12}>*/}
          {/*  <Typography*/}
          {/*    variant={'h2'}*/}
          {/*    style={{ alignItems: 'center', display: 'flex' }}>*/}
          {/*    <img src={BackIcon} alt="Back" className={classes.icon} />*/}
          {/*    <Link*/}
          {/*      className={'pointer'}*/}
          {/*      onClick={(event) => {*/}
          {/*        event.preventDefault();*/}
          {/*        dispatch({*/}
          {/*          type: GlobalActions.setCartStatus.SUCCESS,*/}
          {/*          payload: true,*/}
          {/*        });*/}
          {/*        history.push(Paths.Home);*/}
          {/*      }}>*/}
          {/*      Return to Cart*/}
          {/*    </Link>*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(AccountLogin);
