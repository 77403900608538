import React from 'react';
import OrderTracking from "../../components/Ordertracking"
import Layout from '../../components/Layout';
import {useParams } from 'react-router-dom';

const Ordertracking = (props) => {

  return (
    <div>
         <Layout whiteBG={true}>
            <OrderTracking  ></OrderTracking>
         </Layout>
     
    </div>
  );
};

export default Ordertracking;
