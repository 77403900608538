import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KitIcons from '../../assets/images/ico-single-hero.svg';
import AddIcon from '@material-ui/icons/Add';
import singlekits from '../../assets/images/Gift A Kit/ico-flour.png';
import snack from '../../assets/images/Gift A Kit/ico-snack.svg';
import subscribe from '../../assets/images/Gift A Kit/subscribe.png';
import { fontFamily, lineHeight } from '@material-ui/system';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';
import { useDispatch, useSelector } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    placeHolderImg: {
        height: 96,
        width: 96,
        borderRadius: 48,
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    heading: {
        color: "#535B65",
        fontSize: 22,
        lineHeight: '30px',
        fontFamily: 'HappySchool',
        margin: "20px",
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#C4C4C4 !important',
        borderRadius: '8px',
    },
    input: {
        color: '#6B6B6B',
        fontFamily: 'Andes',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    GiftBtn: {
        fontFamily: 'HappySchool',
        borderRadius: '28px',
        padding: '12px 92px',
        fontSize: '1rem',
        margin: '40px 0 40px',
      },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ShopPopUp = withStyles(styles)((props) => {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const setGiftingDetails =async () => {
       await dispatch(CartActions.setGifting({ payload: true }));
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const { children, classes, onClose, ...other } = props;
    const history = useHistory();

    return (
        <>
            <Button
            className={classes.GiftBtn}
            theme
            onClick={handleClickOpen}
            variant={'contained'}
            color={'primary'}
            disableElevation
            >Gift a Kit Now!</Button>               

            
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

                <DialogContent dividers
                
                >
                <Grid                                      
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                >
                        <Grid item xs={12} sm={12}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                        </Grid>
                    </Grid>
                    
                    <Grid                      
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                       
                     style={{ borderRadius: "10px",  margin: "10px 0" }}
                    >
                        <Grid item xs={12} sm={12}>
                            <Typography variant={'h2'} className={classes.heading}>
                                Choose from the below categories to gift your friends and family.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                    >
                    <Grid
                        container
                        spacing={2}   
                        md={10}
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                            onClick={async() => {
                               await setGiftingDetails();
                                history.push(Paths.ProductListing);
                            }}
                        style={{ cursor:"pointer",background: "#4A79C9", borderRadius: "10px",  padding: "0px 0", margin: "10px 0" }}>                                                                                                
                        <Grid
                            item
                            xs={5}
                            sm={3}                           
                            style={{ display: 'flex', justifyContent: 'center',paddingLeft:"15px" }}>
                            <div className={classes.placeHolderImg}>
                                <img style={{ height: '48px', width: '48px' }} src={singlekits} alt="Kits" />
                            </div>
                        </Grid>
                            <Grid item
                                xs={5}
                                sm={8} >
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    fontFamily: 'Andes',
                                }}>
                                Single Kits
                            </Typography>
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Andes',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    lineHeight: '14px',
                                    marginBottom: 20,
                                }}>
                                Browse our range of easy-to-do, fun baking kits with unique,
                                chef-designed recipes.
                            </Typography>
                        </Grid>


                    </Grid>
                   
                    <Grid
                       item container
                            md={10}
                            spacing={2} 
                        direction="row"
                            alignItems="center"
                            justify="flex-start"
                      
                            onClick={async() => {
                               await  setGiftingDetails();
                                history.push(Paths.ReadyToEat)
                            }}
                        style={{ cursor:"pointer",background: "#FB5E88", borderRadius: "10px", padding: "0px 0", margin: "10px 0" }}>
                        <Grid
                            item
                            xs={5}
                            sm={3}
                            style={{ display: 'flex', justifyContent: 'center',paddingLeft:"15px" }}>
                            <div className={classes.placeHolderImg}>
                                <img style={{ height: '48px', width: '48px' }} src={snack} alt="Kits" />
                            </div>
                        </Grid>
                        <Grid item xs={5} sm={8} >
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    fontFamily: 'Andes',
                                }}>
                                Ready-to-eat Snacks
                            </Typography>
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Andes',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    lineHeight: '14px',
                                    marginBottom: 20,
                                }}>
                                Browse our range of healthy, yummy snacks. Baked fresh, just for you.
                            </Typography>
                        </Grid>


                    </Grid>
                    
                    <Grid
                       spacing={2} 
                        item container
                        md={10}
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                            onClick={async () => {
                               await setGiftingDetails();
                                history.push(Paths.Subscribe)
                            }}
                        style={{ background: "#FAAD55", borderRadius: "10px",  padding: "0px 0", margin: "10px 0"  ,cursor:"pointer"}}>
                        <Grid
                            item
                            xs={5}
                            sm={3}
                            style={{ display: 'flex', justifyContent: 'center',paddingLeft:"15px" }}>
                            <div className={classes.placeHolderImg}>
                                <img style={{ height: '48px', width: '48px' }} src={subscribe} alt="subscribe" />
                            </div>
                        </Grid>
                        <Grid item xs={5} sm={8} >
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    fontFamily: 'Andes',
                                }}>
                                Subscriptions
                            </Typography>
                            <Typography
                                variant={'h2'}
                                style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Andes',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    lineHeight: '14px',
                                    marginBottom: 20,
                                }}>
                                Get your hands on our summer camp kit with easy-to-do, fun baking recipes.
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
});


export default ShopPopUp;