import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KitsIcon from '../../assets/images/ico-kits.png';
import AutoRenewIcon from '../../assets/images/ico-autorenew.png';
import BillIcon from '../../assets/images/ico-bill.png';
import DiscountIcon from '../../assets/images/ico-discount.png';
import Grid from '@material-ui/core/Grid';
import MinusIcon from '../../assets/images/ico-decrease.png';
import PlusIcon from '../../assets/images/ico-increase.png';
import { useSelector } from 'react-redux';
// import { currencySymbol } from './../../utils';

const useStyles = makeStyles({
  root: {
    maxWidth: 505,
    borderRadius: '8px',
    background:
      'linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 72%, #FCAB52 0%, #FCAB52 100%)',
    padding: '15px 15px 0',
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 16,
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    color: '#37474F',
    opacity: 0.8,
    fontWeight: 500,
    paddingTop: 15,
  },
  price: {
    color: '#FCAB52',
    fontWeight: 600,
    fontSize: 20,
  },
  discount: {
    color: '#000000',
    fontSize: 16,
    lineHeight: '20px',
    fontFamily: 'HappySchool',
    fontWeight: 400,
    marginBottom: 50,
  },
  btnSelect: {
    backgroundColor: '#FA4A38 !important',
    color: '#FFFFFF',
    borderRadius: 28,
    fontFamily: 'HappySchool',
    padding: '16px 40px',
  },
  iconSmall: {
    height: 16,
    width: 'auto',
    cursor: 'pointer',
  },
  iconDisabled: {
    height: 16,
    width: 'auto',
    opacity: 0.5,
  },
  counterText: {
    fontSize: 24,
    color: '#fff',
    fontFamily: 'HappySchool',
    padding: '0 8px',
  },
  smallWhiteText: {
    fontSize: 14,
    color: '#fff',
  },
});

const PlanCard = ({ details, addToCartHandler, updateCart }) => {
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  let qty = useSelector((state) => {
    if (
      state.cart &&
      state.cart.subscriptions &&
      state.cart.subscriptions.length
    ) {
      const subscription = state.cart.subscriptions.find(
        (subscription) => subscription.id === details.id
      );
      if (subscription) {
        return subscription.qty;
      } else {
        return 1;
      }
    }
    return 1;
  });

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ paddingBottom: 20 }}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}>
            <Typography variant="body2" className={classes.title}>
              OrangeFigs {details.title}
            </Typography>
            <Typography variant="body2" className={classes.price}>
              {currencySymbol}<span style={{ textDecoration: 'line-through' }}>{details.title === 'Monthly Plan'
                ? (details.price)
                : Math.round((details.price )/ 12)}{' '}</span>{' '}
              {details.title === 'Monthly Plan'
                ? (details.price-details.discount_price)
                : Math.round((details.price-details.discount_price )/ 12)}{' '}
              <small style={{ fontWeight: 400 }}>/mo</small>
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ marginTop: 22 }}
            direction={'row'}
            alignItems={'center'}>
            <img src={KitsIcon} alt="Kits" className={classes.icon} />
            <Typography>4 kits per subscription per month</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ marginTop: 22 }}
            direction={'row'}
            alignItems={'center'}>
            <img src={BillIcon} alt="Bill" className={classes.icon} />
            <Typography>
              {details.title === 'Monthly Plan'
                ? 'billed monthly'
                : 'billed yearly'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ marginTop: 22 }}
            direction={'row'}
            alignItems={'center'}>
            <img
              src={AutoRenewIcon}
              alt="Auto renew"
              className={classes.icon}
            />
            <Typography>auto-renews until cancelled</Typography>
          </Grid>
          {/* <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ marginTop: 22 }}
            direction={'row'}
            alignItems={'center'}>
            <img src={DiscountIcon} alt="Discount" className={classes.icon} />
           
          </Grid> */}
          <CardActions style={{ justifyContent: 'center', marginTop: '15px' }}>
            <Button
              className={classes.btnSelect}
              onClick={() => addToCartHandler(details)}>
              Select this plan
            </Button>
          </CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center">
            <Grid container item xs={12} sm={6}>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h2"
                  className="mt-5"
                  style={{ fontSize: 12, color: '#fff' }}>
                  No. of subscriptions
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                direction="row"
                alignItems="center">
                <img
                  src={MinusIcon}
                  alt="Minus"
                  className={classes.iconSmall}
                  onClick={() =>
                    updateCart(
                      { ...details, qty: qty === 1 ? 1 : qty },
                      'SUBTRACT'
                    )
                  }
                />
                <Typography variant="h2" className={classes.counterText}>
                  {qty || 1}
                </Typography>
                {qty < 4 ? (
                  <img
                    src={PlusIcon}
                    alt="Plus"
                    className={classes.iconSmall}
                    onClick={() =>
                      updateCart(
                        { ...details, qty: qty === 1 ? 2 : qty },
                        'ADD'
                      )
                    }
                  />
                ) : (
                  <img
                    src={PlusIcon}
                    alt="Plus"
                    className={classes.iconDisabled}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {/*<Typography*/}
                {/*  variant="h2"*/}
                {/*  style={{ fontSize: 12, color: '#37474F', opacity: 0.7 }}>*/}
                {/*  {4 * (qty || 1)} kits/mo{' '}*/}
                {/*  {details.title === 'Annual Plan'*/}
                {/*    ? `(total ${details.total_kits * (qty || 1)})`*/}
                {/*    : null}*/}
                {/*</Typography>*/}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              direction="row"
              justify="flex-end"
              alignItems="center">
              <Typography variant="h2" className={classes.smallWhiteText}>
                Total{' '}
                <span style={{ fontSize: 24, fontWeight: 600 }}>
                  {currencySymbol}{(details.price-details.discount_price )* (qty || 1)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PlanCard;
