import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import { currencySymbol } from './../../utils';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
  root: {
    backgroundColor: '#FAF7F3',
    textAlign: 'center',
    borderRadius: '20px 20px 20px 20px',
    margin: '20px 0',
    maxWidth: 328,

    '&:hover': {
      boxShadow: '0 0 50px 0 rgba(0,0,0,0.1)',
    },

    '&:hover :last-child': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  label: {
    backgroundColor: '#FF8256',
    padding: '12px 30px',
    position: 'absolute',
    borderRadius: '20px 0 20px 0',
    color: '#fff',
    fontWeight: 500,
    fontSize: 16,
    margin: 8,
  },
  kitImg: {
    height: 219,
    width: '100%',
    borderRadius: '20px 20px 0 0',
  },
  kitName: {
    fontSize: 28,
    lineHeight: '36px',
    color: '#535B65',
    opacity: 0.9,
    height: 100,
    padding: '30px 20px 10px',
  },
  price: {
    fontSize: 24,
    lineHeight: '36px',
    color: '#535B65',
    fontWeight: 500,
    padding: 24,
  },
  serving: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#535B65',
  },
  btnOnHover: {
    position: 'absolute',
    maxWidth: 328,
    maxHeight: 442,
    height: '219px',
    alignItems: 'center!important',
    justifyContent: 'center',
    background: 'rgba(250, 247, 243, 0.8)',
    padding: '10px 78px',
    // margin:"0 38px",
    borderRadius: '20px 8px 0 0',
    display: 'none',
  },
  btnAddToCart: {
    backgroundColor:'#B52A65!important',
    borderRadius: '24px',
    padding: '10px 40px',
    // margin:"0 38px",
    fontSize: '16px',
    color: '#ffffff',
  },
  bgDisabled: {
    backgroundColor:'#B52A65!important',
    opacity: 0.6,
    borderRadius: '24px',
    padding: '10px 40px',
    // margin:"0 38px",
    fontSize: '16px',
    color: '#ffffff!important',
  },
});

const Snacks = ({ details, addToCartHandler }) => {
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  return (
    <div className={classes.root}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        direction="row"
        justify="center"
        alignItems="center">
        <div>
          <img src={details.image} alt="Snacks" className={classes.kitImg} />
          <Link
            to={`snacks/products/${details.slug}`}
            style={{
              color: '#535B65',
              textDecoration: 'none',
              display: 'block',
            }}>
            <Typography variant="h2" className={classes.kitName}>
              {details.name}
            </Typography>
            <Typography variant="body2" className={classes.serving}>
              Serves {details.serves} | {details.nutrition.calories}{' '}
              kcal/serving
            </Typography>
            <Typography variant="body2" className={classes.price}>
              {currencySymbol}{details.price}{' '}
              <span style={{ fontSize: 10, display: 'contents' }}>
                {' '}
                (incl. of taxes)
              </span>
            </Typography>
          </Link>
        </div>
        <div className={classes.btnOnHover}>
          {details.label === 'Sold out' ? (
            <MuiThemeProvider theme={buttonFont}>
              <Button
                theme
                color={'primary'}
                disableElevation
                fullWidth
                disabled
                className={classes.bgDisabled}
                variant={'contained'}>
                Add to cart
              </Button>
            </MuiThemeProvider>
          ) : (
              <MuiThemeProvider theme={buttonFont}>
                <Button
                  theme
                  color={'primary'}
                  disableElevation
                  fullWidth
                  className={classes.btnAddToCart}
                  onClick={() => addToCartHandler(details)}
                  variant={'contained'}>
                  Add to cart
              </Button>
              </MuiThemeProvider>
            )}
        </div>
      </Grid>
    </div>
  );
};

export default Snacks;
