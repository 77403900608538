import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Giftimage from '../../assets/images/gift1.png';
import ShopPopUp from './shopPopup';
const useStyles = makeStyles((theme) => ({
  banner: {
    height: 322,
    width: 'auto',
  },
  main: {
    backgroundColor: '#F9F7F3',
    padding: '40px 0',
  },
  heading: {
    color: colors.common.gray,
    fontSize: 40,
    lineHeight: '56px',
    fontFamily: 'HappySchool',
    marginBottom: 20,
  },
  desc: {
    color: colors.common.gray,
    fontSize: 28,
    lineHeight: '40px',
    fontWeight: 400,
  },
  GiftBtn: {
    fontFamily: 'HappySchool',
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '40px 0 40px',
  },
}));

const Gift = () => {
  const classes = useStyles();


  return (
    <div className={classes.main}>
      <Container fixed>
        
     
        <Grid container spacing={3} style={{ padding: "40px",
    background: "#F9F7F3" }}>
          <Grid item xs={12} sm={7}>
            <Typography variant={'h2'} className={classes.heading}>
            Gift an Orange Figs Kit Now!
            </Typography>
            <Typography variant={'h2'} className={classes.desc}>
            You can now share the fun experience of baking with your friends and family!
            </Typography>              
          </Grid>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img src={Giftimage} alt="Gift" className={classes.banner} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ShopPopUp/>
          </Grid>
        </Grid>

     
      </Container>
    </div>
  );
};

export default Gift;
