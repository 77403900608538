import SummerCamp from '../../components/SummerCamp';
import React from 'react';
import Cart from '../../components/Cart';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

const SummerCampHome = () => {
  const dispatch = useDispatch();
  const setOpenCart = (val) => {
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: val });
  };
  let { cartStatus } = useSelector((state) => state.global);

  return (
    <div>
      <SummerCamp/>
      <Cart openCart={cartStatus} toggleCart={setOpenCart} />
    </div>
  );
};

export default SummerCampHome;
