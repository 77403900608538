import React, { useEffect, useState } from 'react';
import './index.css';
import { fetchProducts, fetchSchoolsData } from '../../../operations/products';
import CartActions from '../../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../../utils';
import { useDispatch } from 'react-redux';
import GlobalActions from '../../../redux/reducer/globalReducer';
import { firebaseAnalytics } from '../../../firebase';
import ContactUs from './ContactUs';
import {Paths} from './../../../routes/routePaths'

const SummerCamp = (props) => {

//   Paths.SummerCamp = Paths.SchoolIIS;

  const [products, setProducts] = useState([]);
  const [schoolsData, setSchoolsData] = useState([]);
  const dispatch = useDispatch();
  const schoolsRefData = schoolsData.filter(
    (item) =>  item.availability == true
 );

  const kitData = schoolsRefData &&  schoolsRefData.length > 0  ? schoolsRefData[1] : {};
  const kitData2 = schoolsRefData &&  schoolsRefData.length > 0  ? schoolsRefData[0] : {};
  const productUrl = products && products.length > 0 ? products[0] : 'https://firebasestorage.googleapis.com/v0/b/orange-fig.appspot.com/o/recipe_videos%2FWhatsApp%20Video%202021-04-07%20at%208.10.31%20PM.mp4?alt=media&token=bc7bb963-5c2a-45d4-b2f9-916621f693aa'
  const getsummercamplist = async () => {

    const result = await fetchSchoolsData();
    setSchoolsData(result);
  };
  
  useEffect(() => {
    async function fetchSummercamp() {
        await getsummercamplist();
      }
      fetchSummercamp();
  }, []);

useEffect(() => {
    firebaseAnalytics.logEvent("IIS");
}, []);

const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.SNACKS }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
    dispatch(CartActions.setCounrty.success({country:'India',currencySymbol:'₹',homePath:Paths.SchoolIIS }));
};

    return (
        <div>
            <section className="sectionOne">
                {/* <div className="container-fluid nopadding banner-image-o-s" style={{backgroundImage: `uri('../../../assets/images/summarCamp/FigsBackground.png')`}}>
                </div> */}
                <div className="text-center">
                    <img src={require("../../../assets/images/schools/orangefigs-iis-banner-image.png")} alt="Home DIY" />
                </div>
            </section>

            <section className="sectionSeven">
                <h4>Kids Baking Session</h4>
                <p className="text">Take a sneak peep at our fun BAKING experience on 30th September, 2021</p>
                <div className="row col-md-12 justify-content-center">
                    <div className="col-md-6 col-sm-6">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                            <img src={require("../../../assets/images/schools/orangefigs-iis-session-img2.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Memories With Mawaji</h6>
                                <p className="text1">3PM To 4PM</p>
                                <p className="text2">RECIPE: MAWAJI O THE CAKE</p>
                                <button onClick={() => addToCart(kitData)}>Bake Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../../assets/images/schools/orangefigs-iis-session-img1.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Crazy With Carrot</h6>
                                <p className="text1">4:30PM To 5:30PM</p>
                                <p className="text2">RECIPE: carrot turmeric cake</p>
                                <button onClick={() => addToCart(kitData2)}>Bake Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="price-tag">
                <img src={require("../../../assets/images/schools/price-tag.png")} alt="Home DIY" />
            </div>
            <section className="sectionThree-o">
                <div>
                    <h5>All you need to do is keep your chef hats and aprons ready, and also an oven, to come along and bake some fun! Looking forward to seeing you, Team Orange Figs.</h5>
                    <p>P.S.: The Zoom link to join the virtual session and list of pre-requisites will be shared.</p>
                </div>
            </section>
            <ContactUs country="India"/>
            <section className="footer">
                <p>Copyright ©2021 OrangeFigs     All Rights Reserved</p>
            </section>
        </div>
    );
};

export default SummerCamp;
