import firebase from 'firebase/app';
import Message from '../components/Message';
import Notification from '../components/AlertBox';
import MarkIcon from '../assets/images/summarCamp/checkMark.png';

export const currentServerTime = firebase.firestore.FieldValue.serverTimestamp();

export const timestampFromDate = inputDate =>
  firebase.firestore.Timestamp.fromDate(inputDate);

export const currentUser = () => firebase.auth().currentUser;

/*
  MESSAGES
 */

/**
 * Generic error - form validation
 */
export const GenericErrorForm = () =>
Message.error('Kindly fill all the details');
/**
 * Generic error - form validation
 */
export const EnterValidEmail = () =>
  Message.error('Please enter a valid email address.');

/**
 * Generic error - form validation
 */
export const EnterValidMobile = () =>
Message.error('Please enter a valid mobile number.');

/**
 * Generic success - Update
 */
export const GenericSuccessUpdate = () =>
  Message.success('Data updated successfully.');

/**
 * Generic error - Update
 */
export const UnableToUpdate = () =>
  Message.error(
    'Unable to update data at this time. Please try again later.'
  );
/**
 * Generic error - Create
 */
export const UnableToCreate = () =>
  Message.error(
    'Unable to create data at this time. Please try again later.'
  );

/**
 * Generic create
 */
export const CreatedSuccessFully = () =>
  Message.success('Created successfully.');

/**
 * Lead successfully
 */
 export const LeadCreatedSuccessFully = () => {
 Notification({
    imageUrl:MarkIcon,
    title:'Great!',
    // icon: 'success',
    text: "Thanks for reaching out to us our team will connect with you soon...",
    showCancelButton: true,
    cancelButtonText: `Close`
  });
//  Message.success('Thanks for reaching out to us our team will connect with you soon...');
 }

 /**
 * App Comiing soon 
 */
  export const Appstoreclick = () => {
    Notification({
       title:'Coming Soon..!',
       // icon: 'success',
       text: "App will be available soon ...",
       showCancelButton: true,
       cancelButtonText: `Close`
     });
   }




/**
 * payment successfully
 */
 export const PaymentSuccessMessage = () =>
 Message.success('Thank you for your payment. Your tax invoice should reach your e-mail, shortly!');

//  promocode messages
 export const PromocodeSuccessMessage = () =>
 Message.success("Promo code has been succesfully applied");

 export const PromocodefailedMessage = () =>
 Message.error("Please check your promocode");

 export const NewAddressfailedMessage = () =>
 Message.error("Please Enter a different child name");
