import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CancelIcon from '../../assets/images/cancel-icon.png';
import KitIcon from '../../assets/images/kit-icon.png';
import ShippingIcon from '../../assets/images/shipping-icon.png';
import SiblingIcon from '../../assets/images/sibling-icon.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 64,
    width: 64,
    marginBottom: 24,
  },
  main: {
    backgroundColor: '#FAF7F3',
    padding: '70px 0',
  },
  text: {
    // width: 328,
    fontSize: 20,
    lineHeight: '28px',
  },
}));

const details = [
  {
    id: 1,
    icon: KitIcon,
    text: 'Pack of 4 kits  delivered every month. Right at your doorstep.',
    alt: 'Kit',
  },
  {
    id: 2,
    icon: ShippingIcon,
    text: 'Delivered within 5 days of the order for the first shipment.',
    alt: 'Shipping',
  },
  {
    id: 3,
    icon: SiblingIcon,
    text: 'Customise quanity to add subscription for multiple people.',
    alt: 'Sibling',
  },
  {
    id: 4,
    icon: CancelIcon,
    text: 'Cancel anytime before the next billing.',
    link: 'Refer to cancellations.',
    alt: 'Cancel',
  },
];

const AdditionalDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.main}>
      <Container fixed>
        <Grid container spacing={2}>
          {details.map((item) => (
            <Grid
              item
              xs={12}
              sm={3}
              key={item.id}
              style={{
                margin: '20px 0',
                textAlign: 'center',
              }}>
              <img src={item.icon} alt={item.alt} className={classes.icon} />
              <Typography variant={'body1'} className={classes.text}>
                {item.id == 4 ? (
                  <span>
                    {item.text}
                    <a role="button" onClick= {()=>history.push('/returnPolicy')}><u>{item.link}</u></a>
                  </span>
                ) : (
                  item.text
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default AdditionalDetails;
