import Swal from "sweetalert2";
import "./index.css"
const Notification = ({
  icon,
  text,
  title,
  html,
  showCancelButton,
  confirmButtonText,
  cancelButtonText,
  onClose,
  showConfirmButton,
  imageUrl,
  imageWidth,
  imageHeight,
  imageAlt,
  iconHtml,
  timer,
}) =>
  Swal.fire({
    icon,
    title,
    text,
    html,
    showCancelButton,
    confirmButtonColor: "#FF7D4B",
    cancelButtonColor: "#d33",
    confirmButtonText,
    showCloseButton: true,
    cancelButtonText,
    onClose: onClose,
    showConfirmButton,
    iconHtml,
    imageUrl,
    imageWidth,
    imageHeight,
    imageAlt,
    timer
  });

export default Notification