import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BackIcon from '../../assets/images/cart-delete.svg';
import PlusIcon from '../../assets/images/ico-plus.png';
import MinusIcon from '../../assets/images/ico-minus.png';
import DeleteIcon from '../../assets/images/cart-delete.svg';
import CakeIcon from '../../assets/images/temp-cake.jpg';
import EmptyCartImage from '../../assets/images/empty-cart.png';
import { buttonFont } from '../../theme';
import Link from '@material-ui/core/Link';
import { useDispatch, useSelector } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { currentUser } from '../../operations/utils';
import { updateUserData } from '../../operations/onboarding';
import { withRouter } from 'react-router-dom';
import { Paths } from '../../routes/routePaths';
import { months } from '../../utils';
// import { currencySymbol } from './../../utils';

const useStyles = makeStyles({
  cartMain: {
    maxWidth: 560,
    paddingBottom: 90,
  },
  topSection: {
    padding: '80px 40px 57px',
    backgroundColor: '#FAF7F3',
    borderBottom: '1px solid #D8D8D8',
  },
  emptyCart: {
    height: 181,
    width: 'auto',
    padding: '100px 0px',
  },
  ecText: {
    fontSize: 20,
    lineHeight: '24px',
    color: '#535B65',
    padding: '20px 80px 40px',
    // width: 191,
    cursor: 'pointer',
  },
  ecLink: {
    fontSize: 21,
    lineHeight: '24px',
  },
  icon: {
    height: 24,
    width: 'auto',
    cursor: 'pointer',
  },
  iconSmall: {
    height: 16,
    width: 'auto',
    cursor: 'pointer',
  },
  productSection: {
    padding: '56px 40px 48px',
  },
  description: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '36px',
    padding: 0,
  },
  productImage: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 6,
  },
  totalItems: {
    fontSize: 24,
    lineHeight: '36px',
  },
  price: {
    fontWeight: 400,
    opacity: '0.7',
    marginRight: 32,
  },
  totalPrice: {},
  quantity: {
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.7,
    margin: '0 11px',
  },
  delete: {
    height: 12,
    width: 'auto',
    cursor: 'pointer',
  },
  fullList: {
    width: 'auto',
  },
  divider: {
    border: '1px solid #D8D8D8',
    width: '100%',
    margin: '0 40px',
  },
  subTotal: {
    padding: '56px 40px 100px',
  },
  subTotalHeading: {
    fontWeight: 400,
    opacity: '0.7',
  },
  subTotalDesc: {
    fontWeight: 400,
    opacity: '0.7',
    fontSize: 12,
  },
  grandTotal: {
    fontSize: 28,
    lineHeight: '34px',
    fontWeight: 600,
    float: 'right',
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '18px 92px',
    fontSize: '1rem',
    margin: '10px 0',
  },
  btnpromo: {
    borderRadius: '28px',
    padding: '18px 92px',
    fontSize: '1rem',
    margin: '20px 0',
  },
});

const Cart = (props) => {
  const classes = useStyles();

  let {
    products = [],
    snacks = [],
    subscriptions = [],
    grandTotal = 0,
    subTotal = 0,
    currencySymbol
  } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const emptyCart = !products.length && !snacks.length&& !subscriptions.length;

  const removeFromCart = (item) => {
    dispatch(CartActions.removeFromCart(item));
  };

  const updateCart = (item, operation) => {
    dispatch(CartActions.updateCart({ item, operation }));
  };

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={props.openCart}
        onClose={() => props.toggleCart(false)}>
        <div className={classes.cartMain}>
          <Grid container>
            <Grid container item xs={12} sm={12} className={classes.topSection}>
              <Grid item xs={4} sm={4}>
                <img
                  src={BackIcon}
                  alt={'Back'}
                  className={classes.icon}
                  onClick={() => props.toggleCart(false)}
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography variant="body2" className={classes.totalItems}>
                  Your Cart ({products.length + snacks.length+subscriptions.length})
                </Typography>
              </Grid>
            </Grid>
            {emptyCart ? (
              <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12} sm={12}>
                  <img
                    src={EmptyCartImage}
                    alt="Empty cart"
                    className={classes.emptyCart}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h2" className={classes.ecText}>
                    Your cart is empty. <br />
                    Find your favorites.
                  </Typography>
                  {/* Blocking old flow  summer camp*/}
                  {/* <Typography variant={'h2'} color={'primary'}>
                    <Link
                      href="#"
                      onClick={() => {
                        props.toggleCart(false);
                        props.history.push(Paths.Subscribe);
                      }}
                      className={classes.ecLink}>
                      Browse Subscription Plans
                    </Link>
                  </Typography> */}
                </Grid>
              </Grid>
            ) : (
                <>
                  <Grid container>
                    {products.length
                      ? products.map((item, index) => (
                        <CartItem
                          item={item}
                          key={index}
                          removeFromCart={removeFromCart}
                          updateCart={updateCart}
                        />
                      ))
                      : null}
                    {snacks.length
                      ? snacks.map((item, index) => (
                        <CartItem
                          item={item}
                          key={index}
                          removeFromCart={removeFromCart}
                          updateCart={updateCart}
                        />
                      ))
                      : null}

                  {subscriptions.length
                      ? subscriptions.map((item, index) => (
                        <CartItem
                          item={item}
                          key={index}
                          removeFromCart={removeFromCart}
                          updateCart={updateCart}
                        />
                      ))
                      : null}
                  </Grid>
                  <hr className={classes.divider} />
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ padding: '0 40px' }}>
                   
                  </Grid>
                  <Grid
                    container
                    className={classes.subTotal}
                    justify={'space-between'}>
                    <Grid item xs={8} sm={8}>
                      <Typography
                        variant={'h2'}
                        className={classes.subTotalHeading}>
                        Sub total
                    </Typography>
                      <Typography variant={'h2'} className={classes.subTotalDesc}>
                        {/* Shipping & taxes will be calculated at checkout */}
                        Price inclusive of taxes and shipping
                    </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Typography variant={'h2'} className={classes.grandTotal}>
                        {currencySymbol}{subTotal && subTotal.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ padding: '0 40px' }}>
                    <MuiThemeProvider theme={buttonFont}>
                    <Typography variant={'h5'} className={classes.subTotalDesc}>
                        *Note : Items can't be added/edited once you checkout.
                    </Typography>
                      <Button
                        fullWidth
                        theme
                        className={classes.btnSubmit}
                        onClick={async () => {
                          const user = currentUser();
                          if (user) {
                            await updateUserData(user.uid, {
                              cart: {
                                products,
                                snacks,
                                subscriptions,
                                grandTotal,
                              },
                            });
                          }
                          props.history.push(Paths.Account);
                        }}
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Check Out
                    </Button>
                    </MuiThemeProvider>
                  </Grid>
                  {/*<Grid*/}
                  {/*  item*/}
                  {/*  xs={12}*/}
                  {/*  lg={12}*/}
                  {/*  md={12}*/}
                  {/*  style={{ padding: '0 40px', textAlign: 'center' }}>*/}
                  {/*  <Typography variant={'h2'} color={'primary'}>*/}
                  {/*    <Link href="#" onClick={() => null}>*/}
                  {/*      Browse market place*/}
                  {/*    </Link>*/}
                  {/*  </Typography>*/}
                  {/*</Grid>*/}
                </>
              )}
          </Grid>
        </div>
      </Drawer>
    </div>
  );
};
export default withRouter(Cart);

const CartItem = ({ item, removeFromCart, updateCart }) => {
  const classes = useStyles();
  const { month } = item;
  const cart = useSelector((state) => state.cart);
  const currencySymbol = cart.currencySymbol;
  const isPreorder = month ? !(month === months[new Date().getMonth()]) : false;

  return (
    <Grid container item xs={12} sm={12} className={classes.productSection}>
      <Grid item xs={3} sm={4}>
        <img
          src={item.image || CakeIcon}
          alt={item.name || 'item'}
          className={classes.productImage}
        />
      </Grid>
      <Grid container alignItems="center" item xs={6} sm={6}>
        <Typography variant={'h2'} className={classes.description}>
          {item.name}
        </Typography>
        {isPreorder && (
          <Typography
            style={{
              fontSize: 14,
              color: '#faab53',
            }}>{`Note: Item will be shipped on 30th ${months[new Date().getMonth()]
              } `}</Typography>
        )}

        <Grid item xs={12} sm={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography variant={'h2'} className={classes.price}>
            {currencySymbol}{(item.price - (item.discount_price ? item.discount_price : 0))}
            </Typography>
            <img
              src={MinusIcon}
              alt="Minus"
              className={classes.iconSmall}
              onClick={() => updateCart(item, 'SUBTRACT')}
            />
            <Typography variant={'h2'} className={classes.quantity}>
              {item.qty}
            </Typography>
            <img
              src={PlusIcon}
              alt="Plus"
              className={classes.iconSmall}
              onClick={() => updateCart(item, 'ADD')}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        sm={2}
        container
        direction={'column'}
        justify={'space-between'}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginTop: '20px',
        }}>
        <img
          src={DeleteIcon}
          alt="Item"
          className={classes.delete}
          onClick={() => removeFromCart(item)}
        />
        <Typography variant={'h2'} className={classes.totalPrice}>
        {currencySymbol}{(item.price - (item.discount_price ? item.discount_price : 0)) * item.qty}
        </Typography>
      </Grid>
    </Grid>
  );
};
