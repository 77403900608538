import { firestore } from '../../firebase';

const fetchProducts = async () => {
  try {
    const response = await firestore.collection('products').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

const fetchtestPromoCode = async (promocode) => {
  try {
  const response = await firestore.collection('promocodes').where('code', '==',promocode.toString() ).get();
  return response.docs.map((item) => {
  return { ...item.data(), id: item.id };
  });
  } catch (e) {     
  console.warn(e);
  }
  };

const fetchUsers = async () => {

  try {
    const response = await firestore.collection('users').orderBy('updatedTime', 'desc').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });

  } catch (error) {
    console.log("users list-", error);
    console.warn(error)
  }

}

const UpdateShipping = async (uids) => {
const uid="NlcBr0gHQqczZQjzElNc7HdN0at1";
  try {
    const response = await firestore.collection('users').doc(uid).get();

    const prevShippingAdress = response.data().MultipleAddress;
    const firstKidName = response.data().firstKidName?response.data().firstKidName:"firstkidname";
    const firstKidDOB = response.data().firstKidDOB?response.data().firstKidDOB:"firstkidDate";
    // console.log(prevShippingAdress);

// ,
    const obj =   {
      firstKidName,
      firstKidDOB,
      stateName: prevShippingAdress[0].stateName, 
      addressTwo: prevShippingAdress[0].addressTwo,
       cityName: prevShippingAdress[0].cityName, 
       zipcode: prevShippingAdress[0].zipcode, 
       addressOne: prevShippingAdress[0].addressOne,
      }

    await firestore.collection('users').doc(uid).update({
      shippingAddress: {...obj},
      MultipleAddress:[obj,obj,obj]
    });

  } catch (e) {
    console.log(e);
    return e;
  }

}




const fetchProductDetails = async (id) => {
  try {
    const response = await firestore.collection('products').doc(id).get();
    return {
      ...response.data(),
      id: response.id,
    };
  } catch (e) {
    console.warn(e);
  }
};

const fetchProductDetailsBySlug = async (slug) => {

  try {
    const response = await firestore.collection('products').where('slug', '==', slug).get();
    let result;
    response.forEach((el) => { result = { ...el.data(), id: el.id } })

    return result
  } catch (e) {
    console.warn(e);
  }
};

const fetchSummerCamps = async () => {
  try {
    const response = await firestore.collection('summercamp').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

const fetchSchoolsData = async () => {
  try {
    const response = await firestore.collection('schools').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};


const fetchPromoCodes = async () => {
  try {
    const response = await firestore.collection('promocodes').get();
    return response.docs.map((item) => {
      return { ...item.data(), id: item.id };
    });
  } catch (e) {
    console.warn(e);
  }
};

export { fetchtestPromoCode,UpdateShipping, fetchUsers, fetchPromoCodes, fetchProducts, fetchProductDetails, fetchProductDetailsBySlug, fetchSummerCamps, fetchSchoolsData };
