import React from 'react';
import TeamMemberCard from '../TeamMemberCard';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.common.gray,
    lineHeight: '40px',
    marginBottom: '20px',
    fontSize: '28px',
  },
  teamGroupContainer: {
    marginBottom: '67px',
  },
}));

const TeamGroup = ({ color, heading, team }) => {
  const classes = useStyles();
  return (
    <div className={classes.teamGroupContainer}>
      <Typography className={classes.heading} variant="h2">
        {heading}
      </Typography>

      <Grid style={{ marginLeft: 0, paddingLeft: 0 }} container>
        {team.map((el, index) => (
          <Grid key={index} item xs={12} lg={4} md={6} sm={6}>
            <TeamMemberCard
              name={el.name}
              designation={el.designation}
              image={el.image}
              color={color}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TeamGroup;
