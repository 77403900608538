import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccountInformation from './accountInformation';
import CartSummary from './cartSummary';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';

const useStyles = makeStyles((theme) => ({
  root: {},
  leftSection: {
    height: '100vh',
  },
}));

const Account = ({ user, }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [steps ,setStep] = useState(1)
  const [promocode ,setpromocode] = useState(false);
  const [ discount,setdiscount] = useState({});

  const handlestep=(e)=>{
       setStep(e);
  }

  const handlePromoCode=(e)=>{
    setpromocode(e);
}

const handlediscount= (e)=>{
  setdiscount(e);
}



 
  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify={'space-between'}>
        <Grid item xs={12} sm={1} />
        <AccountInformation user={user} changestate={handlestep} promocode={promocode} discount={discount}/>
        <CartSummary  user= {user} step={steps} promohandle={handlePromoCode}  handlediscount={handlediscount}/>
      </Grid>
    </div>
  );
};

export default Account;
