import { Container } from '@material-ui/core';
import React from 'react';
import Layout from '../../components/Layout';
import TeamGroup from './components/TeamGroup';
import HeroAboutUs from './hero';
import ContactUs from '../../components/ContactUs';
/**
 * Import Images here
 */

import Gopi from '../../assets/images/team/Gopi.png';
import NithinUpadyaya from '../../assets/images/team/NithinUpadyaya.png'; 
import SarojiniNaidu from '../../assets/images/team/SarojiniNaidu.png';
import ShailajaRao from '../../assets/images/team/ShailajaRao.png';
import SunnyNarang from '../../assets/images/team/SunnyNarang.png';
import TarunDhacha from '../../assets/images/team/TarunDhacha.png';
import Udayanganguly from '../../assets/images/team/Udayanganguly.png';
import HariVaslamani from '../../assets/images/team/HariVaslamani.png'; 


const orangeFigsTeam = [
  {
    name: 'Gopi',
    designation: 'CEO',
    image: Gopi,
  },
  {
    name: 'Chef Nitin Upadyaya',
    designation: 'Culinary Director',
    image: NithinUpadyaya,
  },
 
  
];

const advisoryBoard = [
  {
    name: 'Shialaja Rao',
    designation: 'Founder, Future Kids School',
    image: ShailajaRao,
  },
 
  {
    name: 'Sunny Narang',
    designation: 'Founder, RASA Investment Group',
    image: SunnyNarang,
  },
  {
    name: 'Dr. Sarojini Naidu',
    designation: 'Food & Nutrition Expert',
    image: SarojiniNaidu,
  },
  // {
  //   name: 'Udayan Ganguly',
  //   designation: 'Founder, RASA Investment Group',
  //   image: Udayanganguly,
  // },
  {
    name: 'Hari Vadlamani',
    designation: 'Founder, RASA Investment Group',
    image: HariVaslamani,
  },
];

const AboutUs = () => {
  return (
    <Layout whiteBG={true}>
      <HeroAboutUs />
      <Container>
        <TeamGroup
          heading="OrangeFigs Team"
          color="blue"
          team={orangeFigsTeam}
        />

        <TeamGroup heading="Advisory Board" color="pink" team={advisoryBoard} />
      
      </Container>
      <ContactUs/>
    </Layout>
  );
};

export default AboutUs;
