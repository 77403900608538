import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Alert from '../../components/Alert';
import { buttonFont } from '../../theme';
import Button from '@material-ui/core/Button';
import BackIcon from '../../assets/images/ico-back-orange.png';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { fetchUserDataById, updateUserData } from '../../operations/onboarding';
import {
  paymentHandler,
  subscriptionPaymentHandler,
} from '../../operations/payment';
import { ZipcodeVerification } from '../../operations/common';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CartActions from '../../redux/reducer/cartReducer';
import { useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { getStates } from 'country-state-picker'
// import { useDispatch, useSelector } from 'react-redux';
const CustomRadio = withStyles({
  root: {
    color: "#FA7E70",
    '&$checked': {
      color: "#FA7E70",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CustomCheckbox = withStyles({
  root: {
    color: "#FA7E70",
    '&$checked': {
      color: "#FA7E70",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
  },
  icon: {
    height: 22,
    width: 'auto',
    marginRight: 10,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important',
    borderRadius: '8px',
  },
  input: {
    color: '#6B6B6B',
    fontFamily: 'Andes',
  },
  headingLeft: {
    color: '#535B65',
    fontSize: 20,
    lineHeight: '24px',
    
  },
  headingRight: {
    color: '#535B65',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    float: 'right',
  },
  btnSubmit: {
    borderRadius: '28px',
    padding: '12px 92px',
    fontSize: '1rem',
    margin: '10px 0',
  },
  subTotalDesc: {
    fontWeight: 200,
    opacity: '0.7',
    fontSize: 12,
  },
  alertArea: {
    minHeight: '75px',
    height: 'auto',
  },
  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ShippingDetails = ({ user, history, handleBack ,promocode,discount}) => {
  const classes = useStyles();
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeServiceable, setZipcodeServiceable] = useState([]);
  const [grandtotal, setGrandTotal] = useState(0);
  const [userData, setUserData] = useState({});
  const [alert, setAlert] = useState(false);
  const [firstKidName, setFirstKidName] = useState('');
  const [firstKidDOB, setFirstKidDOB] = useState('');
  const [multipleAddressIndex,setMultipleAddressIndex] = useState(0); 
  const [multipleAddress,setMultipleAddress] = useState([]);
  const [selectedValue,setselectedValue] = useState('OldAddress'); 
  const [selectedSubmit,setSelectedSubmit] = useState(false); 
  const [disableOldAddress,setdisableOldAddress] = useState(false);
  const [guestName,setGuestName] = useState("");
  const [guestNumber,setGuestNumber] = useState("");
  const [discountDetails,setDiscountDetails] = useState({})
  const [GiftDetailsEnable,setGiftDetailsEnable] = useState(false);
  const [states,setStatesData] = useState([]);
  const [firstKidNameFlag, setFirstKidNameFlag] = useState(false);
  const [firstKidDOBFlag, setFirstKidDOBFlag] = useState(false);
  const [addressOneFlag, setAddressOneFlag] = useState(false);
  const [addressTwoFlag, setAddressTwoFlag] = useState(false);
  const [cityNameFlag, setCityNameFlag] = useState(false);
  const [stateNameFlag, setStateNameFlag] = useState(false);
  const [zipcodeFlag, setZipcodeFlag] = useState(false);
  let {   
    giftdetails=false,
    country
  } = useSelector((state) => state.cart);
  const isIndia = country === 'India' ? true : false;
  var zip_length = 0;
  zip_length = isIndia ? 6 : 5;
  const countryCode = isIndia ? 'in' : 'us';
  const dispatch = useDispatch();

  let { shippingTotal = 0, tax = 0 } = useSelector((state) => state.cart);


  const getUserData = async (user) => {
    const userData = await fetchUserDataById(user.uid);
    setUserData(userData);
    if (userData.cart) {
      dispatch(CartActions.setCart({ cart: userData.cart }));
    }
    // console.log("on load");
    console.log(userData);
  };

  const getZipcode = async () => {
    const result = await ZipcodeVerification();
    setZipcodeServiceable(result);
  };

  useEffect(()=>{
    // console.log("click is being hndled");
    async function fetchData(user) {
      await getUserData(user);
      await getZipcode();
    }
    if (user) {
      fetchData(user);
    }
    // console.log("discount----",discount);
    setDiscountDetails(discount);
    let states = getStates(countryCode);
    setStatesData(states);
    console.log("states list--",states)
  },[promocode,discount]);

  useEffect(() => {
    async function fetchData(user) {
      await getUserData(user);
      await getZipcode();
    }
    if (user) {
      fetchData(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  useEffect(() => {


    async function fetchData(user) {
      await getUserData(user);
      await getZipcode();
    }
    if (user) {
      fetchData(user);   
    }

    if (giftdetails) {
      setGiftDetailsEnable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findZipcode = (value) => {
    return zipcodeServiceable.findIndex((zip) => {
      return zip.Pincode === value;
    });
  };

  const validate = () => {
    (firstKidName?.length) ? setFirstKidNameFlag(false) : setFirstKidNameFlag(true);
    (firstKidDOB?.length) ? setFirstKidDOBFlag(false) : setFirstKidDOBFlag(true);
    (addressOne?.length) ? setAddressOneFlag(false) : setAddressOneFlag(true);
    (cityName?.length) ? setCityNameFlag(false) : setCityNameFlag(true);
    (stateName?.length) ? setStateNameFlag(false) : setStateNameFlag(true);
    (zipcode?.length) ? setZipcodeFlag(false) : setZipcodeFlag(true);
    (verifyZipcodelength()) ? setZipcodeFlag(false) : setZipcodeFlag(true);
    return (
      !firstKidName.length||
      !firstKidDOB||
      !addressOne.length ||
      // !addressTwo.length ||
      !cityName.length ||
      !stateName.length ||
      !zipcode.length||
      !verifyZipcodelength()
    //   findZipcode(zipcode) === -1
    );
  };

  const checkIfUpdated = () => {
    const shipping = userData.MultipleAddress;
    if (shipping) {
      return (
        addressOne !== shipping[multipleAddressIndex].addressOne ||
        addressTwo !== shipping[multipleAddressIndex].addressTwo ||
        cityName !== shipping[multipleAddressIndex].cityName ||
        stateName !== shipping[multipleAddressIndex].stateName ||
        shipping[multipleAddressIndex].zipcode !== zipcode
        
      );
    } else {
      return true;
    }
  };
  const verifyZipcodelength= ()=>{
        if(zipcode.length===zip_length) {
            return true
        }
        else {
            return false
        }
    }
  // const verifyZipcode = (value) => {
  //   if (findZipcode(value) === -1) {
  //     setAlert(true);
  //   } else {
  //     setAlert(false);
  //   }
  // };

 

 const handleKidsNameChange = (event)=>{

   const {value,name} = event.target;
   console.log("nameindex",event.target);
   const index = multipleAddress.findIndex((item) => item.firstKidName === value);
   
    setFirstKidName(value);

   setMultipleAddressIndex(index);
 }
 const handleStateChange = (event)=>{
  const {value} = event.target;
  console.log("nameindex",event.target);
  setStateName(value);
}
 const handleMultipleAddress = ()=>{
  if (userData.MultipleAddress) {    
    const shipping = userData.MultipleAddress;
    // console.log("Firtst kid name",shipping[multipleAddressIndex].firstKidName);
    setMultipleAddress(shipping);
    setFirstKidName(shipping[multipleAddressIndex].firstKidName);
    setFirstKidDOB(shipping[multipleAddressIndex].firstKidDOB);
    setAddressOne(shipping[multipleAddressIndex].addressOne);
    setAddressTwo(shipping[multipleAddressIndex].addressTwo);
    setCityName(shipping[multipleAddressIndex].cityName);
    setStateName(shipping[multipleAddressIndex].stateName);
    setZipcode(shipping[multipleAddressIndex].zipcode);
    setGrandTotal(userData.cart.grandTotal);
  }
  setselectedValue("OldAddress");  
   
 }

 const handleNewAddress = ()=>{
  setselectedValue("NewAddress");
  setFirstKidName("");
  setFirstKidDOB("");
  setAddressOne("");
  setAddressTwo("");
  setCityName("");
  setStateName("");
  setZipcode("");
 }


  useEffect(() => {
if(userData && !selectedSubmit){
    if (userData.MultipleAddress) {  
      const shipping = userData.MultipleAddress;
      setdisableOldAddress(false);
      setselectedValue("OldAddress");
      // console.log("Firtst kid name",shipping[multipleAddressIndex].firstKidName);
      setMultipleAddress(shipping);
      setFirstKidName(shipping[multipleAddressIndex].firstKidName);
      setFirstKidDOB(shipping[multipleAddressIndex].firstKidDOB);
      setAddressOne(shipping[multipleAddressIndex].addressOne);
      setAddressTwo(shipping[multipleAddressIndex].addressTwo);
      setCityName(shipping[multipleAddressIndex].cityName);
      setStateName(shipping[multipleAddressIndex].stateName);
      setZipcode(shipping[multipleAddressIndex].zipcode);
      setGrandTotal(userData.cart.grandTotal);
    }else {
      setdisableOldAddress(true);
      setselectedValue("NewAddress");
    }
  }
  }, [userData]);

  useEffect(() => {
    if (userData.MultipleAddress) { 
      // console.log("change multiple address callled");   
      const shipping = userData.MultipleAddress;
      // console.log("Firtst kid name",shipping[multipleAddressIndex].firstKidName);
      setMultipleAddress(shipping);
      setFirstKidName(shipping[multipleAddressIndex].firstKidName);
      setFirstKidDOB(shipping[multipleAddressIndex].firstKidDOB);
      setAddressOne(shipping[multipleAddressIndex].addressOne);
      setAddressTwo(shipping[multipleAddressIndex].addressTwo);
      setCityName(shipping[multipleAddressIndex].cityName);
      setStateName(shipping[multipleAddressIndex].stateName);
      setZipcode(shipping[multipleAddressIndex].zipcode);
      setGrandTotal(userData.cart.grandTotal);
    }
  }, [multipleAddressIndex]);

  const updatedateMultipleAdddress = ()=>{

    multipleAddress[multipleAddressIndex].firstKidName = firstKidName
    multipleAddress[multipleAddressIndex].firstKidDOB = firstKidDOB
    multipleAddress[multipleAddressIndex].addressOne = addressOne
    multipleAddress[multipleAddressIndex].addressTwo = addressTwo
    multipleAddress[multipleAddressIndex].cityName = cityName
    multipleAddress[multipleAddressIndex].stateName = stateName
    multipleAddress[multipleAddressIndex].zipcode = zipcode

  }

  

  return (
    <div className={classes.root}>
    
      <Container fixed>

        <Grid item container xs={12} sm={12} style={{ marginTop: 10 }}>
        <Grid item container xs={12} sm={12} >
            <Grid>
            <FormControlLabel
        control={<CustomCheckbox 
          checked={GiftDetailsEnable}
         onChange={()=>{setGiftDetailsEnable(!GiftDetailsEnable)}}
          name="checkedG" />}
        label="I want to gift a kit?"
      />
            </Grid>
            {GiftDetailsEnable&&(
              <>
            <Grid item xs={12} sm={12}>

              <Typography variant={'h2'} className={classes.headingLeft}>
                Gifting Details
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth     
                label="Name"
                variant="outlined"
                style={{ margin: '12px 0' }}
                value={guestName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setGuestName(event.target.value)}
                helperText={!guestName && 'Please enter name *'}
                error={!guestName && 'Please enter name *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
                <Grid item xs={12} lg={12} md={12}
                 style={{ margin: '12px 0' }}
                >
                  
                <PhoneInput
                    country={ country === 'India' ? 'in' :'us'}
                   
                    value={guestNumber}
                    enableSearch = {true}
                    placeholder={"Enter Phone Number"}
                    required={true} 
                   autoFocus= {true}
                    onChange={phone =>  setGuestNumber( phone)}
                    />
              {!guestNumber && <FormHelperText error={true}>Please enter number *</FormHelperText>}
            </Grid>
            
            </>)}
          </Grid> 
            <Grid item xs={12} sm={12} style={{ margin: GiftDetailsEnable ? "30px 0px 10px" : ""}}>
              <Typography variant={'h2'} className={classes.headingLeft}>
                Details | Child
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Grid container  row xs={12} sm={12} lg={12} md={12}>
                <Grid item xs={6} sm={6} lg={6} md={6}>
            <FormControl component="fieldset">     
      <RadioGroup  aria-label="position" name="position" defaultValue="top">
      <FormControlLabel 
      value="end"
     
         control={ <CustomRadio 
          disabled={disableOldAddress}          
          checked={selectedValue === 'OldAddress'}
          onChange={handleMultipleAddress}
          value="a"
         ></CustomRadio>} 
         label="Select Child Name" />
                
      </RadioGroup>
    </FormControl>
    </Grid>
    <Grid item xs={6} sm={6} lg={6} md={6}>
            <FormControl component="fieldset">     
      <RadioGroup  aria-label="position" name="position" defaultValue="top">
    
       
          <FormControlLabel value="end"
         control={ <CustomRadio
        
          checked={selectedValue === 'NewAddress'}
          onChange={handleNewAddress}
          value="b"
         ></CustomRadio>} 
         label="Enter New Child Name" />
        
      </RadioGroup>
    </FormControl>
    </Grid>
           </Grid>
            </Grid>
            
            <Grid item xs={12} lg={12} md={12}>        
             { selectedValue === 'OldAddress'&&<FormControl variant="outlined" className={classes.formControl}
             style={{ margin: '12px 0' }}>
        <InputLabel id="demo-simple-select-outlined-label">Child's Name</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Child's Name"
          value={firstKidName} 
                onChange={handleKidsNameChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
        >
         { userData.MultipleAddress&&userData.MultipleAddress.map((item,idx)=>(   
        <MenuItem value={item.firstKidName} id={idx}>{item.firstKidName}</MenuItem>
         ))
        }
          
        </Select>
        </FormControl>
           }
           {selectedValue === 'NewAddress'&&<TextField
                fullWidth
                label="Child's Name"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={firstKidName}
                onChange={(event) => setFirstKidName(event.target.value)}
                helperText={(!firstKidName && firstKidNameFlag) && 'Please enter child name *'}
                error={!firstKidName && firstKidNameFlag && 'Please enter child name *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />}
           
           
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Kid’s d.o.b."
                type="date"
                value={moment(firstKidDOB).format('YYYY-MM-DD')}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setFirstKidDOB(event.target.value)}
                helperText={!firstKidDOB && firstKidDOBFlag && 'Please select child Date of Birth *'}
                error={!firstKidDOB && firstKidDOBFlag && 'Please select child Date of Birth *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        <Grid container spacing={3}>
          <Grid item container xs={12} sm={12} style={{ margin:"30px 0px 10px"}}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingLeft}>
                Shipping details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'h2'} className={classes.headingRight}>
                All fields are required
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={addressOne}
                onChange={(event) => setAddressOne(event.target.value)}
                helperText={(!addressOne && addressOneFlag) && 'Please enter Address Line 1 *'}
                error={(!addressOne && addressOneFlag) && 'Please enter Address Line 1 *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                value={addressTwo}
                onChange={(event) => setAddressTwo(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                value={cityName}
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setCityName(event.target.value)}
                helperText={(!cityName && cityNameFlag) && 'Please enter a city name *'}
                error={(!cityName && cityNameFlag) && 'Please enter a city name *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} lg={12} md={12}>
               <TextField
                fullWidth
                label="State"
                value={stateName}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => setStateName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              /> 
     
            </Grid> */}
            <Grid item xs={12} lg={12} md={12}>        
             {<FormControl variant="outlined" className={classes.formControl}
             style={{ margin: '12px 0' }}>
            <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="State"
                    value={stateName} 
                    defaultValue={stateName}
                    onChange={handleStateChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
            >
            { states.map((item,idx)=>(   
              <MenuItem value={item} id={idx}>{item}</MenuItem>
            ))
            }
              
            </Select>
            {(!stateName && stateNameFlag) && <FormHelperText error={true}>Please select state *</FormHelperText>}
            </FormControl>
              }         
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Zip code"
                type="number"
                value={zipcode}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                onChange={(event) => {
                  let value = event.target.value;
                  if (value.toString().length > zip_length) return;
                  setZipcode(event.target.value);

                //   if (value.toString().length === 6) {
                //     verifyZipcode(event.target.value);
                //   }
                }}
                // helperText={((!zipcode && zipcodeFlag) ? 'Please enter/check  zipcode' :'')}  
                // error={((!zipcode && zipcodeFlag) ? 'Please enter/check  zipcode' :'')}  
                helperText={((!zipcode && zipcodeFlag) || (zipcode && zipcode.toString().length != zip_length) && zipcodeFlag) && 'Please enter/check  zipcode *'}
                error={((!zipcode && zipcodeFlag) || (zipcode && zipcode.toString().length != zip_length) && zipcodeFlag) && 'Please enter/check  zipcode *'}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                fullWidth
                label="Country"
                value={isIndia?'India':'USA'}
                variant="outlined"
                style={{ margin: '12px 0' }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                }}
                // onChange={(event) => setStateName(event.target.value)}
                InputLabelProps={{
                  style: {
                    color: '#6B6B6B',
                  },
                }}
              />
            </Grid>
            
           
            
            {alert && (
              <Grid className={classes.alertArea} item xs={12}>
                <Alert severity="error">
                  Thank you for showing interest. Unfortunately, we don’t
                  currently deliver at this zipcode. We will make sure to update
                  you at the email/phone number provided.
                </Alert>
              </Grid>
            )}
            {/* <Grid item xs={12} lg={12} md={12} style={{ marginTop: 20 }}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" />}
                    label="Billing address is same as shipping address"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid> */}
          </Grid>

   
            

          <Grid item xs={12} lg={12} md={12}>
            <MuiThemeProvider theme={buttonFont}>
            <Typography variant={'h6'} className={classes.subTotalDesc}>
              *Note : We Accept Visa and Master Cards only, At this time we cannot accept Amex cards.
            </Typography>
              <Button
                // disabled={validate()}
                fullWidth
                theme
                className={classes.btnSubmit}
                onClick={async () => {
               

                    const guestDetails={
                      guestName, guestNumber: "+" + guestNumber.toString(),
                    }
                //   console.log("working");
                  if (!validate()) {
                    if (checkIfUpdated()) {
                      setSelectedSubmit(true);
                   if(selectedValue === 'OldAddress'){
                   await  updatedateMultipleAdddress();
                      await updateUserData(user.uid, {
                        MultipleAddress: [
                          ...multipleAddress
                        ],
                      });
                    }
                    if(selectedValue === 'NewAddress'){

                      const obj={ 
                        firstKidDOB,
                        firstKidName,
                        addressOne,
                        addressTwo,
                        cityName,
                        stateName,
                        zipcode,
                        country
                      }

                      await updateUserData(user.uid, {
                        MultipleAddress: [
                          ...multipleAddress,obj
                        ],
                      });
                    }
                    }
                    await  getUserData(user);
                    if (
                       userData.cart.subscriptions &&
                       userData.cart.subscriptions.length                    
                    ) {
                      const subscription = userData.cart.subscriptions[0];
                      const discountprice = discount?.discountamount?discount.discountamount:0;


                      
                      await paymentHandler(
                        ((subscription.price-subscription.discount_price)*subscription.qty)-discountprice,
                        user,
                        userData,
                        dispatch,
                        history,
                        {
                          firstKidDOB,
                          firstKidName,
                          addressOne,
                          addressTwo,
                          cityName,
                          stateName,
                          zipcode,
                          country
                        },
                        tax,
                        shippingTotal,
                        discount,
                        guestDetails,
                        country
                      );
                    } else {
                      await paymentHandler(
                        userData.cart.grandTotal,
                        user,
                        userData,
                        dispatch,
                        history,
                        {
                          firstKidDOB,
                          firstKidName,
                          addressOne,
                          addressTwo,
                          cityName,
                          stateName,
                          zipcode,
                          country
                        },
                        tax,
                        shippingTotal,
                        discount,
                        guestDetails,
                        country
                      );
                    }
                  }
                }}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                Confirm & Pay
              </Button>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h2'}
              style={{ alignItems: 'center', display: 'flex' }}>
              <img src={BackIcon} alt="Back" className={classes.icon} />
              <Link
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleBack();
                }}>
                Return to User details
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(ShippingDetails);
